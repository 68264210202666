import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";

const StepCard = () => {
  return (
    <div className={Styles.stepBox}>
      <div className={Styles.stepTitle}>
        <h1 className={Styles.title}>Cara Mudah Pesan Motor</h1>
      </div>
      <section className={Styles.cardStep}>
        <div className={Styles.card}>
          <div className={Styles.topCardStep}>
            <div className={Styles.number}>
              <span className={Styles.numb}>1</span>
            </div>
            <Icon icon={"document"} className={Styles.icon} />
          </div>

          <div className={Styles.bottomCardStep}>
            <span className={Styles.textStep}>Pesan Motor</span>
            <span className={Styles.ketText}>
              Pilih motor dan pembayaran yang anda inginkan
            </span>
          </div>
        </div>

        <div className={Styles.card}>
          <div className={Styles.topCardStep}>
            <div className={Styles.number}>
              <span className={Styles.numb}>2</span>
            </div>
            <Icon icon={"list"} className={Styles.icon} />
          </div>

          <div className={Styles.bottomCardStep}>
            <span className={Styles.textStep}>Isi Data</span>
            <span className={Styles.ketText}>
              Isi dan lengkapi form pesanan anda
            </span>
          </div>
        </div>

        <div className={Styles.card}>
          <div className={Styles.topCardStep}>
            <div className={Styles.number}>
              <span className={Styles.numb}>3</span>
            </div>
            <Icon icon={"smile"} className={Styles.icon} />
          </div>

          <div className={Styles.bottomCardStep}>
            <span className={Styles.textStep}>Motor Sampai</span>
            <span className={Styles.ketText}>
              Pesanan di proses dan akan segera di kirim ke hadapan anda!
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StepCard;
