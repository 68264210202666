import { useEffect, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";
import useWindowSize from "@Hooks/useWindowSize";

export const CarouselProduct = ({ data }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardRefs = useRef([]);
  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    cardRefs.current[currentIndex + 1]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      cardRefs.current[currentIndex - 1]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  const { width } = useWindowSize();
  const wrapperRef = useRef(null);
  const itemWidth = 324;

  const handleScroll = () => {
    if (wrapperRef.current) {
      const scrollLeft = wrapperRef.current.scrollLeft;
      const totalWidth =
        wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth;
      setCurrentIndex(Math.round(scrollLeft / itemWidth));
      const isEnd = scrollLeft >= totalWidth;
      wrapperRef.current.style.paddingRight = isEnd ? "16px" : "16px";
      // wrapperRef.current.style.paddingLeft = scrollLeft === 0 ? "16px" : "0";
    }
  };

  useEffect(() => {
    const currentWrapper = wrapperRef.current;
    if (currentWrapper) {
      currentWrapper.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, [width]);
  const [isPaddingLeft, setIsPaddingLeft] = useState(false);
  useEffect(() => {
    setIsPaddingLeft(true);
  }, []);
  console.log(currentIndex);
  console.log(data?.length);

  return (
    <div className={Styles.promoCardBody} style={{ rowGap: "24px" }}>
      <div className={Styles.titlePromoCard} style={{ paddingInline: "16px" }}>
        <span className={Styles.title}>Harga Spesial</span>
        {width < 600 && (
          <div
            onClick={() => navigate("/products")}
            className={Styles.buttonSection}
          >
            Lihat Semua
          </div>
        )}
      </div>
      <div
        ref={width <= 768 ? wrapperRef : null}
        className={Styles.wrapperCardProducts}
        style={{
          overflowX: "scroll",
          whiteSpace: "nowrap",
          paddingLeft: isPaddingLeft ? "16px" : "",
        }}
      >
        {data?.map((data, idx) => (
          <div
            className={`flex-column ${Styles.card}`}
            key={idx}
            ref={(el) => (cardRefs.current[idx] = el)}
          >
            <div className={`flex-between ${Styles.header}`}>
              <div className={`flex-column`}>
                <span className={Styles.title}>{data?.name}</span>
                <span>{data?.category}</span>
              </div>
              <div className={Styles.discount}>
                <span>-{data?.promo}</span>
              </div>
            </div>
            <div className={`flex-center ${Styles.image}`}>
              <img
                src={fileBaseUrl + data?.colors[0]?.imgUrl}
                alt="images-motor"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className={Styles.wrappPricing}>
              <span className={Styles.otr}>Harga OTR Jakarta, mulai dari</span>
              <div className={`flex-between ${Styles.offers}`}>
                <div className={`flex-column ${Styles.pricing}`}>
                  <span>Rp{data?.newPrice?.toLocaleString("id-ID")}</span>
                  <span>Rp{data?.price.toLocaleString("id-ID")}</span>
                </div>
                <div className={Styles.colors}>
                  {data?.colors.slice(0, 4)?.map((color, idx) => (
                    <div className={Styles.chooseColor} key={idx}>
                      <span
                        className={Styles.colorBullet}
                        style={
                          color?.name?.toLowerCase() === "white" ||
                          color?.name?.toLowerCase() === "putih" ||
                          color?.name?.toLowerCase()?.includes("white") ||
                          color?.name?.toLowerCase()?.includes("putih")
                            ? {
                                border: "0.2px solid #2B2B2B",
                                background: color?.code,
                              }
                            : { background: color?.code }
                        }
                      ></span>
                    </div>
                  ))}
                  {data?.colors?.length > 4 ? (
                    <span>+{data?.colors?.length - 4}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className={Styles.button}
              onClick={() => navigate(`products/detail/${data?.motorId}`)}
            >
              <span>Lihat Detail</span>
            </div>
          </div>
        ))}
      </div>
      <div className={Styles.buttonBoxCard}>
        {width > 600 && (
          <Link to={"/products"} className={Styles.buttonSection}>
            Lihat Semua
          </Link>
        )}
        <div className={Styles.between}>
          <div
            className={Styles.wrapperBtn}
            onClick={currentIndex === 0 ? () => {} : () => handlePrev()}
            is-done={(currentIndex === 0)?.toString()}
          >
            <Icon icon={"panahSamping"} className={Styles.icon} />
          </div>
          <div
            className={Styles.wrapperBtn}
            onClick={
              data?.length <= (width <= 1024 ? 2 : 4) ||
              currentIndex === data?.length - (width <= 1024 ? 2 : 4)
                ? () => {}
                : () => handleNext()
            }
            is-done={
              data?.length === undefined
                ? "true"
                : data?.length === null
                ? "true"
                : data?.length <= (width <= 1024 ? 2 : 4)
                ? "true"
                : currentIndex === data?.length - (width <= 1024 ? 2 : 4)
                ? "true"
                : "false"
            }
          >
            <Icon icon={"panahSamping"} className={Styles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};
