import { API } from "@Config/index";

export const getDataMotor = (
  // sort = "",
  price = "",
  limit = null,
  page = null,
  searchQuery = "",
  category = [],
  sort = "",
  rangePriceStart = 0,
  rangePriceEnd = 0
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const categoryQuery = category
        ?.map((cat) => `category[]=${cat}`)
        ?.join("&");
      const priceRangeQuery =
        rangePriceStart && rangePriceEnd
          ? `&rangePrice[start]=${rangePriceStart}&rangePrice[end]=${rangePriceEnd}`
          : "";
      const { data } = await API.get(
        `/variant/item?${
          searchQuery
            ? `price=${price}&search=${searchQuery}&limit=${limit}&page=${page}&${categoryQuery}${priceRangeQuery}`
            : `price=${price}&limit=${limit}&page=${page}&sort=${sort}&${categoryQuery}${priceRangeQuery}`
        }`
      );
      if (data.response && data?.response?.data?.length === 0) {
        resolve({ message: "No data found", data: [] });
      } else {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const getTotalDataCategory = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/category/motor`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const getDataHeroPage = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/landingpage?limit=3&page=1`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
