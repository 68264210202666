import React from "react";

export default function RefreshIcon({ color = "#357ABB", ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6498 2.34999C12.0198 0.719992 9.70978 -0.220008 7.16978 0.0399922C3.49978 0.409992 0.479777 3.38999 0.0697772 7.05999C-0.480223 11.91 3.26978 16 7.99978 16C11.1898 16 13.9298 14.13 15.2098 11.44C15.5298 10.77 15.0498 9.99999 14.3098 9.99999C13.9398 9.99999 13.5898 10.2 13.4298 10.53C12.2998 12.96 9.58978 14.5 6.62978 13.84C4.40978 13.35 2.61978 11.54 2.14978 9.31999C1.30978 5.43999 4.25978 1.99999 7.99978 1.99999C9.65978 1.99999 11.1398 2.68999 12.2198 3.77999L10.7098 5.28999C10.0798 5.91999 10.5198 6.99999 11.4098 6.99999H14.9998C15.5498 6.99999 15.9998 6.54999 15.9998 5.99999V2.40999C15.9998 1.51999 14.9198 1.06999 14.2898 1.69999L13.6498 2.34999Z"
        fill={color}
      />
    </svg>
  );
}
