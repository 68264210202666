import React from "react";
import Styles from "./styles.module.scss";
import SearchIcon from "assets/svg/search";

export default function SearchInput({
  placeholder,
  onFocus = () => {},
  value = "",
  setValue = () => {},
  onClickSearch = () => {},
}) {
  const handleChangeSearch = (e) => {
    setValue(e?.target?.value);
  };
  return (
    <form className={`${Styles.filterSearch} flex-align-center`}>
      <input
        onFocus={onFocus}
        placeholder={placeholder}
        type="text"
        className={Styles.searchInput}
        onChange={handleChangeSearch}
        value={value}
      />
      <button type="button" className={`${Styles.searchBtn} flex-center`}>
        <SearchIcon color="#FFFFFF" onClick={onClickSearch} />
      </button>
    </form>
  );
}
