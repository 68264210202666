import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import LayoutLandingPage from "@Organism/LandingPage";
import { getDataMotor as getDataLandingPage } from "@Services/getDataLandingPage";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || null;
  const limit = params?.get("limit") || null;
  const price = params?.get("price") || "";
  return defer({
    getData: getDataLandingPage(price, limit, page),
  });
}
export default function LandingPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await
        resolve={getData}
        errorElement={<EmptyHandlerGetData />}
        children={(value) => <LayoutLandingPage data={value} />}
      ></Await>
    </Suspense>
  );
}
