import React from "react";
import Styles from "./style.module.scss";
import errorImgIcon from "@Assets/img/error.png";
import RefreshIcon from "@Assets/svg/refresh";
import useWindowSize from "@Hooks/useWindowSize";

export default function EmptyHandlerGetData() {
  const { width } = useWindowSize();
  return (
    <div className={Styles.wrapperErrorGetData}>
      <div className={Styles.containError}>
        <img src={errorImgIcon} alt="error-icon-info" />
        <h1>Oppss...!</h1>
        <p>Terjadi kesahalahan, data tidak dapat di muat</p>
      </div>
      <form action="">
        <button type="submit" className={Styles.btnModalError}>
          <strong>Refresh</strong>
          <span>
            <RefreshIcon
              width={width < 768 ? "16" : "20"}
              height={width < 768 ? "16" : "20"}
              color="white"
            />
          </span>
        </button>
      </form>
    </div>
  );
}
