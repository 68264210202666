import React from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";

export default function PageHeader({ primaryText, secondaryText }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className={`${Styles.header} flex-column-align-center`}>
      <h2>{primaryText}</h2>
      <span>{secondaryText}</span>
      {pathname.includes("/products/detail") && (
        <button
          className={Styles.backButton}
          onClick={() => navigate("/products")}
        >
          <Icon
            icon={"arrow-right"}
            className={Styles.backIcon}
            color={"#fff"}
          />
          <span>Kembali</span>
        </button>
      )}
    </div>
  );
}
