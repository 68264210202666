import React from "react";
import Styles from "./style.module.scss";
import ImgWhatasApp from "@Assets/img/whatsapp-logo.png";
import { useLocation } from "react-router-dom";
export default function ButtonWhatsApp() {
  const { pathname, search } = useLocation();
  const number = "+6281287995892";
  const handleOpenWhatsApp = () => {
    const url = `https://wa.me/${number}`;
    window.open(url, "_blank");
  };
  return (
    <div
      className={Styles.ButtonWhatsApp}
      onClick={() => handleOpenWhatsApp()}
      mobile-list-products={
        pathname.includes("products") && search.startsWith("?query")
          ? "true"
          : "false"
      }
    >
      <span>Contact Us</span>
      <div className={Styles.wrapImg}>
        <img src={ImgWhatasApp} alt="whatsapp-logo" />
      </div>
    </div>
  );
}
