import Styles from "./style.module.scss";
import CarouselLandingPage from "@Molecule/LandingPage/CarouselLandingPage";
import { useLocation, useNavigate } from "react-router-dom";
import { Advantage } from "@Molecule/LandingPage/Advantage";
// import TestimonyCard from "@Molecule/LandingPage/TestimonyCard";
import { CarouselProduct } from "@Molecule/LandingPage/carouselCard";
import StepCard from "@Molecule/LandingPage/StepOrder";
import Images from "@Theme/Images/images";
import { useEffect } from "react";
import HeroLandingPage from "@Molecule/LandingPage/TopLandingPage";

const dataBenefit = [
  {
    id: 1,
    title: "Peduli Kepada Pelanggan",
    ket: "Kami menawarkan layanan sebaik mungkin untuk pelanggan",
  },

  {
    id: 2,
    title: "100% Garansi Kepuasan",
    ket: "Gratis pengembalian dibawah 3 bulan",
  },
  {
    id: 3,
    title: "Harga Terbaik",
    ket: "Kami menawarkan harga terbaik untuk pelanggan",
  },
];

// const dataTestimoni = [
//   {
//     id: 1,
//     name: "Andi1",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 0,
//   },

//   {
//     id: 2,
//     name: "Andi2",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },
//   {
//     id: 3,
//     name: "Andi3",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },

//   {
//     id: 4,
//     name: "Andi4",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },
//   {
//     id: 5,
//     name: "Andi5",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },

//   {
//     id: 6,
//     name: "Andi6",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: "",
//   },

//   {
//     id: 7,
//     name: "Andi7",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 1,
//   },

//   {
//     id: 8,
//     name: "Andi8",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },

//   {
//     id: 9,
//     name: "Andi9",
//     date: "Januari 2021",
//     message:
//       "sangat puas.barang ori.tapi agak lambat dalam pengiriman.mau order barang lainnya masih kosong.gear box supra dan handel rem scopi.....",
//     ratting: 5,
//   },
// ];

// const dataPromo = [
//   {
//     id: 1,
//     name: "AEROX",
//     price: "23000000",
//     discount: "-",
//     type: "Matic",
//     promo: "-",
//     img: image1,
//   },
//   {
//     id: 2,
//     name: "AEROX",
//     price: "23000000",
//     discount: "-",
//     type: "Matic",
//     promo: "-",
//     img: image1,
//   },
//   {
//     id: 3,
//     name: "AEROX",
//     price: "23000000",
//     discount: "20000000",
//     type: "Matic",
//     promo: "2,5 juta",
//     img: image1,
//   },
//   {
//     id: 4,
//     name: "AEROX",
//     price: "23000000",
//     discount: "20000000",
//     type: "Matic",
//     promo: "2,5 juta",
//     img: image1,
//   },
//   {
//     id: 5,
//     name: "AEROX",
//     price: "23000000",
//     discount: "20000000",
//     type: "Matic",
//     promo: "2,5 juta",
//     img: image1,
//   },

//   {
//     id: 6,
//     name: "AEROX",
//     price: "23000000",
//     discount: "20000000",
//     type: "Matic",
//     promo: "2,5 juta",
//     img: image1,
//   },
// ];
const dataCarousel = [
  {
    id: 1,
    title: "Yamaha NMAX 2023",
    description:
      "Yamaha NMAX 2023 telah hadir! Ambil motor favoritmu sekarang juga.",
    image: Images.NMAX_RED,
  },
  {
    id: 2,
    title: "Servis",
    description:
      "Penuhi kebutuhan motor Yamaha anda dengan melakukan servis berkala di tempat kami.",
    image: Images.SERVICES_IMG,
  },
  {
    id: 3,
    title: "Spare Part",
    description:
      "Penuhi kebutuhan motor Yamaha anda dengan mengganti sparepart dan ordendil terbaru untuk motor anda.",
    image: Images.SPAREPARTS_YAMAHA,
  },
];

export default function LayoutLandingPage({ data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    navigate(`${pathname}?query=motor&price=PROMO&limit=12&page=1`);
  }, [navigate, pathname]);
  return (
    <div className={Styles.topBody}>
      <section className={Styles.wrapperTop}>
        <HeroLandingPage />
      </section>
      <div className={`${Styles.promoCarouselBox}`}>
        <CarouselProduct data={data?.response?.data} />
      </div>
      <section className={Styles.wrapperBenefit}>
        <div className={Styles.boxBenefit}>
          {dataBenefit?.map((el, idx) => {
            return (
              <div className={Styles.box} key={`benefit-${idx}`}>
                <span className={Styles.title}>{el.title}</span>
                <span className={Styles.ket}>{el.ket}</span>
              </div>
            );
          })}
        </div>
      </section>

      <div className={Styles.carouselImageBox}>
        <CarouselLandingPage data={dataCarousel} />
      </div>

      <div className={Styles.boxStep}>
        <StepCard />
      </div>

      <div className={Styles.advantageBox}>
        <Advantage />
      </div>

      {/* <div className={Styles.testiCard}>
        <TestimonyCard data={dataTestimoni} />
      </div> */}
    </div>
  );
}
