import React from "react";

export default function ChevronDownIcon({ color = "#323232", props }) {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.12021 0.28957L6.00022 4.16957L9.88021 0.28957C10.2702 -0.10043 10.9002 -0.10043 11.2902 0.28957C11.6802 0.67957 11.6802 1.30957 11.2902 1.69957L6.70021 6.28957C6.31021 6.67957 5.68021 6.67957 5.29022 6.28957L0.700215 1.69957C0.310215 1.30957 0.310215 0.67957 0.700215 0.28957C1.09021 -0.0904297 1.73021 -0.10043 2.12021 0.28957Z"
        fill={color}
      />
    </svg>
  );
}
