// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TEXT_PERMATA_MOTOR: require("@Assets/img/text-permata-motor.png"),
  SERVICES_IMG: require("@Assets/img/servis-yamaha.png"),
  SPAREPARTS_YAMAHA: require("@Assets/img/spareparts.png"),
  STANDARD_NMAX: require("@Assets/img/std-nmax-motor.png"),
  NMAX_RED: require("@Assets/img/n-max-red.png"),
  XMAX_SILVER: require("@Assets/img/SilverXmax2023.png"),
  TIRES: require("@Assets/img/tire.png"),
  ENGINE_DETAIL: require("@Assets/img/engine-detail.png"),
  FRAME_DETAIL: require("@Assets/img/frame-detail.png"),
  DIMENSION_DETAIL: require("@Assets/img/dimension-detail.png"),
  ELECTRICAL_DETAIL: require("@Assets/img/electrical-detail.png"),
  FOOTER_LOGO_TEXT: require("@Assets/img/footer-logo-landing-page.png"),
  FIRST_BANNER: require("@Assets/img/first-banner.png"),
  SECOND_BANNER: require("@Assets/img/second-banner.png"),
  THIRD_BANNER: require("@Assets/img/third-banner.png"),
  MAXI_BLUE_LOGO: require("@Assets/img/maxi-blue-logo.png"),
  CLASSY_BLUE_LOGO: require("@Assets/img/classy-blue-logo.png"),
  MATIC_BLUE_LOGO: require("@Assets/img/matic-blue-logo.png"),
  SPORT_BLUE_LOGO: require("@Assets/img/sport-blue-logo.png"),
  OFF_ROAD_BLUE_LOGO: require("@Assets/img/off-road-blue-logo.png"),
  MOPED_BLUE_LOGO: require("@Assets/img/moped-blue-logo.png"),
  MAXI_WHITE_LOGO: require("@Assets/img/maxi-white-logo.png"),
  CLASSY_WHITE_LOGO: require("@Assets/img/classy-white-logo.png"),
  MATIC_WHITE_LOGO: require("@Assets/img/matic-white-logo.png"),
  SPORT_WHITE_LOGO: require("@Assets/img/sport-white-logo.png"),
  OFF_ROAD_WHITE_LOGO: require("@Assets/img/off-road-white-logo.png"),
  MOPED_WHITE_LOGO: require("@Assets/img/moped-white-logo.png"),
};
