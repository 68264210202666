import React from "react";
import Styles from "./styles.module.scss";
import useWindowSize from "@Hooks/useWindowSize";

export default function ListingPagination({ page, setPage, maxPage }) {
  const { width } = useWindowSize();

  const renderPageButtons = () => {
    const buttons = [];
    if (maxPage === 1) {
      return (
        <div
          key={`only`}
          onClick={() => setPage(1)}
          className={`${Styles.filterPageBullet} ${
            page === 1 && Styles.active
          }`}
        >
          <span>1</span>
        </div>
      );
    }
    buttons.push(
      <div
        key={`first`}
        onClick={() => setPage(1)}
        className={`${Styles.filterPageBullet} ${page === 1 && Styles.active}`}
      >
        <span>1</span>
      </div>
    );

    // Kondisi untuk menampilkan start ellipsis
    if (page > 3) {
      buttons.push(
        <div
          key="start-ellipsis"
          className={`${Styles.pageEllipsis} flex-center`}
          // style={width < 768 ? { display: "none" } : { undefined }}
        >
          <span>...</span>
        </div>
      );
    }

    const startPage = Math.max(2, page - 1);
    const endPage = Math.min(
      maxPage - 1,
      width < 460 && page > 2 ? page + 1 : page > 3 ? page + 1 : page + 2
    );

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <div
          key={`page-${i}`}
          onClick={() => setPage(i)}
          className={`${Styles.filterPageBullet} ${
            page === i && Styles.active
          }`}
        >
          <span>{i}</span>
        </div>
      );
    }

    // Kondisi untuk menampilkan end ellipsis
    if (page < maxPage - 2) {
      buttons.push(
        <div
          key="end-ellipsis"
          className={`${Styles.pageEllipsis} flex-center`}
        >
          <span>...</span>
        </div>
      );
    }

    buttons.push(
      <div
        key={`last`}
        onClick={() => setPage(maxPage)}
        className={`${Styles.filterPageBullet} ${
          page === maxPage && Styles.active
        }`}
      >
        <span>{maxPage}</span>
      </div>
    );

    return buttons;
  };

  return (
    <div className={`${Styles.filterPagination} flex-justify-center`}>
      {renderPageButtons()}
    </div>
  );
}
