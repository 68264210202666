import React from "react";
import Styles from "./style.module.scss";
import EmptyImages from "@Assets/img/EmptyBox.png";
export default function EmptyHandler({
  title = "Data Masih Kosong",
  description = "Saat ini belum ada barang yang ditambahkan kedalam daftar spareparts.",
}) {
  return (
    <div className={Styles.wrapperEmpty}>
      <img src={EmptyImages} alt="empty-images" />
      <div className={Styles.wrapDescripiton}>
        <h1>{title}</h1>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
}
