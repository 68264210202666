import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import { getDataHeroPage } from "@Services/getDataLandingPage";
import { fileBaseUrl } from "@Config/index";
import Spinner from "@Atom/Spinner";
import { useNavigate } from "react-router-dom";

async function fetchBlob(url) {
  const response = await fetch(url);
  return response.blob();
}

export default function HeroLandingPage() {
  const [imageSourceUrl, setImageSourceUrl] = useState([]);
  const [fadeIn, setFadeIn] = useState(0);
  const [isMove, setIsMove] = useState(false);

  const { width } = useWindowSize();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getDataHeroPage();
      setData(response?.motors);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (imageSourceUrl?.length !== 0) {
      const interval = setInterval(() => {
        setIsMove(true);
        setTimeout(() => {
          setIsMove(false);
          setFadeIn((prevFadeIn) =>
            prevFadeIn === data?.length - 1 ? 0 : prevFadeIn + 1
          );
        }, 500);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [data?.length, imageSourceUrl?.length]);

  const getDataImages = async (imageUrl) => {
    try {
      let imageList = [];
      for (let index = 0; index < imageUrl?.length; index++) {
        const uri = imageUrl[index];
        const res = await fetchBlob(fileBaseUrl + uri);
        imageList?.push(URL.createObjectURL(res));
      }
      setImageSourceUrl(imageList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDataImages(data.map((el) => el.Files[0]?.url));
  }, [data]);
  const navigate = useNavigate();
  return (
    <section className={Styles.wrapperTopLandingpage}>
      <div className={Styles.imgBackground}>
        {imageSourceUrl?.length === 0 ? (
          <Spinner />
        ) : (
          <div className={Styles.wrapperContain}>
            <div className={Styles.leftArea}>
              <img
                src={imageSourceUrl[fadeIn]}
                alt="motor"
                is-move={isMove?.toString()}
              />
              <div
                style={width > 900 ? { display: "none" } : { undefined }}
                className={Styles.buttonMobile}
                onClick={() => navigate("products/detail/" + data[fadeIn]?.id)}
              >
                <span>Lihat Detail</span>
              </div>
              <div
                className={Styles.wrapperFootMobile}
                style={width > 900 ? { display: "none" } : { undefined }}
              >
                {data?.map((el, idx) => (
                  <div
                    className={Styles.footDescription}
                    is-active={(fadeIn === idx)?.toString()}
                    key={`variant-mobile-${idx}`}
                  >
                    <div is-active={(fadeIn === idx)?.toString()}>
                      <h2>0{idx + 1}</h2>
                      <LoadingContent
                        fadeIn={fadeIn}
                        idx={idx}
                        className={Styles.dividerLoad}
                        imageList={imageSourceUrl}
                      />
                    </div>
                    <div
                      className={Styles.nameAndPrice}
                      is-active={(fadeIn === idx)?.toString()}
                    >
                      <h3>{el?.name} </h3>
                      <span>
                        Rp{" "}
                        {parseInt(el?.Variants[0]?.price)?.toLocaleString(
                          "id-ID"
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={Styles.rightArea}>
              <div className={Styles.topDecription}>
                <div>
                  <h1>{data[fadeIn]?.name} </h1>
                  <p>{data[fadeIn]?.description}</p>
                </div>
                <div
                  className={Styles.button}
                  onClick={() =>
                    navigate("products/detail/" + data[fadeIn]?.id)
                  }
                >
                  <span>Lihat Detail</span>
                </div>
              </div>
              <div className={Styles.wrapperFoot}>
                {data?.map((el, idx) => (
                  <div
                    className={Styles.footDescription}
                    is-active={(fadeIn === idx)?.toString()}
                    key={`variant-${idx}`}
                  >
                    <div>
                      <h2>0{idx + 1}</h2>
                      <LoadingContent
                        fadeIn={fadeIn}
                        idx={idx}
                        className={Styles.dividerLoad}
                        imageList={imageSourceUrl}
                      />
                    </div>
                    <div className={Styles.nameAndPrice}>
                      <h3>{el?.name}</h3>
                      <span>
                        Rp{" "}
                        {parseInt(el?.Variants[0]?.price)?.toLocaleString(
                          "id-ID"
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

const LoadingContent = ({ fadeIn, idx, imageList, ...props }) => {
  const [loadingImage, setLoadingImage] = useState(0);
  useEffect(() => {
    const increment = 1;
    const intervalTime = 50;
    const duration = 5000;

    setLoadingImage(0);
    if (imageList?.length !== 0) {
      const interval = setInterval(() => {
        setLoadingImage((prev) => {
          const newValue = prev + increment;
          return newValue > 100 ? 100 : newValue;
        });
      }, intervalTime);

      setTimeout(() => {
        clearInterval(interval);
      }, duration);

      return () => clearInterval(interval);
    }
  }, [fadeIn, imageList?.length]);

  return (
    <span
      style={{
        width: `${loadingImage}%`,
      }}
      {...props}
      is-active={(fadeIn === idx)?.toString()}
    ></span>
  );
};
