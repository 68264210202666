import { useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";

export default function CarouselLandingPage({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const len = data.length - 1;
  useEffect(() => {
    const timer = setTimeout(() => {
      const newIndex = (activeIndex + 1) % data.length;
      setActiveIndex(newIndex);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex, data.length]);
  return (
    <div className={Styles.carouselBody}>
      <div className={Styles.inner}>
        <div
          className={Styles.chevronLeft}
          onClick={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
        >
          <Icon icon={"chevron-left"} size={24} color={"white"} />
        </div>
        <div
          className={Styles.chevronRight}
          onClick={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        >
          <Icon icon={"chevron-right"} size={24} color={"white"} />
        </div>
        {data?.map((el, index) => {
          return (
            <div
              className={`${
                activeIndex === index
                  ? Styles.innerActive && Styles.slides
                  : Styles.innerInactive
              }`}
              key={el.id}
            >
              <div className={Styles.descBox}>
                <span className={Styles.name}>{el.title}</span>
                <span className={Styles.desc}>{el.description}</span>
              </div>

              <div className={Styles.imageBox}>
                <img src={el.image} alt="images" />
              </div>
            </div>
          );
        })}
      </div>

      <div className={Styles.radioButton}>
        {data?.map((el, index) => {
          return (
            <div key={el.id}>
              <Icon
                icon={activeIndex === index ? "radio-black" : "radio-white"}
                size={16}
                onClick={() => {
                  setActiveIndex(index);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
