import LandingPage, { loader as getDataLandingPage } from "@Pages/LandingPage";
import Detail, { loader as getDetailMotor } from "@Pages/Product/Detail";
import Motor, { loader as getAllMotor } from "@Pages/Product/Motor";
import Products from "@Pages/index";
import App, { loader as getDataCategory } from "App";
const { createBrowserRouter } = require("react-router-dom");

const routes = [
  {
    path: "/",
    element: <App />,
    loader: getDataCategory,
    children: [
      {
        index: true,
        element: <LandingPage />,
        loader: getDataLandingPage,
      },
      {
        path: "/products",
        element: <Products />,
        children: [
          {
            index: true,
            element: <Motor />,
            loader: getAllMotor,
          },
          {
            path: "detail/:id",
            element: <Detail />,
            loader: getDetailMotor,
          },
        ],
      },
      {
        path: "/services",
        element: <h1>Halaman Servis</h1>,
      },
      {
        path: "/spareparts",
        element: <h1>Halaman Sparepart</h1>,
      },
      {
        path: "/testimonials",
        element: <h1>Halaman Testimonial</h1>,
      },
      {
        path: "/cookies",
        element: <h1>Halaman Cookies</h1>,
      },
      {
        path: "/terms",
        element: <h1>Halaman Ketentuan Dan Layanan</h1>,
      },
      {
        path: "/privacy-policy",
        element: <h1>Halaman Keamanan Privasi</h1>,
      },
    ],
  },
];
const route = createBrowserRouter(routes);
export default route;
