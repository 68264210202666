import React from "react";

export default function ChevronRightIcon({ color = "#357ABB", ...props }) {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.37999 19.0098C0.86999 19.4998 1.65999 19.4998 2.14999 19.0098L10.46 10.6998C10.85 10.3098 10.85 9.6798 10.46 9.2898L2.14999 0.979805C1.65999 0.489805 0.86999 0.489805 0.37999 0.979805C-0.11001 1.4698 -0.11001 2.2598 0.37999 2.7498L7.61999 9.9998L0.36999 17.2498C-0.11001 17.7298 -0.11001 18.5298 0.37999 19.0098Z"
        fill={color}
      />
    </svg>
  );
}
