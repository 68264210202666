import Styles from "./styles.module.scss";
import LOGO from "@Assets/img/PermataMotorLogo.png";
// import LOGO_TEXT from "@Assets/PermataMotorText.png";
import LOGO_TEXT_BLACK from "@Assets/img/PermataMotorTextBlack.png";
import Icon from "@Atom/Icon";

export default function LoginShow({ handleDone, handleRoute }) {
  return (
    <div className={Styles.bodyLogin}>
      <div className={Styles.logoPermata}>
        <div className={Styles.imagesBox}>
          <img src={LOGO} alt="" className={Styles.imageLogo} />
          <img src={LOGO_TEXT_BLACK} alt="" className={Styles.logoText} />
        </div>
        <Icon
          icon={"x-mark"}
          size={32}
          onClick={handleDone}
          className={Styles.iconXmark}
        />
      </div>

      <div className={Styles.inputLoginContainer}>
        <div className={Styles.descBox}>
          <span className={Styles.login}>MASUK</span>
          <span className={Styles.desc}>Cukup gunakan nomor telepon anda.</span>
        </div>

        <div className={Styles.inputBox}>
          <input type="text" placeholder="Nomor Telepon" />
          {/* <span className={Styles.buttonLogin}>Masuk</span> */}
          <input className={Styles.buttonLogin} type="submit" value="Masuk" />
        </div>

        {/* <div className={Styles.lineBox}>
          <div className={Styles.lineLeft}></div>
          <span className={Styles.or}>atau</span>
          <div className={Styles.lineLeft}></div>
        </div>

        <div className={Styles.googleLoginBox}>
          <span className={Styles.descGoogleLogin}>
            Lanjut menggunakan opsi dibawah ini
          </span>
          <div className={Styles.buttonGoggleLogin}>
            <Icon icon={"google"} size={20} />
            <span className={Styles.title}>Lanjut menggunakan Google</span>
          </div>
        </div> */}

        <div className={Styles.routeRegister}>
          <span className={Styles.textBlack}>Belum memiliki akun?</span>
          <span className={Styles.textBlue} onClick={handleRoute}>
            Daftar sekarang
          </span>
        </div>
      </div>
    </div>
  );
}
