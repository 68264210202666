import Styles from "./styles.module.scss";
// import { ListingBanner } from "components/Molecule";
import PageHeader from "components/Molecule/PageHeader";
import useWindowSize from "@Hooks/useWindowSize";
import RefreshIcon from "@Assets/svg/refresh";
import Icon from "@Atom/Icon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ListingPagination from "components/Molecule/ListingPagination";
import SearchInput from "components/Atom/SearchInput";
import ChevronLeftIcon from "assets/svg/chevronLeft";
import ChevronRightIcon from "assets/svg/chevronRight";
import ChevronDownIcon from "assets/svg/chevronDown";
import { useLocation, useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import EmptyHandler from "@Molecule/EmptyHandler";
import { getTotalDataCategory } from "@Services/getDataLandingPage";
import PriceRange from "@Atom/InputField";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Spinner from "@Atom/Spinner";
export default function ProductListing({ data, isNotFound }) {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [activeCategories, setActiveCategories] = useState(["Semua"]);
  const [totalDataMaxi, setTotalDataMaxi] = useState(0);
  const [totalDataClassy, setTotalDataClassy] = useState(0);
  const [totalDataMatic, setTotalDataMatic] = useState(0);
  const [totalDataSport, setTotalDataSport] = useState(0);
  const [totalDataOffRoad, setTotalDataOffRoad] = useState(0);
  const [totalDataMoped, setTotalDataMoped] = useState(0);
  const [totalAllData, setTotalAllData] = useState(0);
  const [dataCategory, setDataCategory] = useState([]);
  const isValidNumber = (value) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };
  const getTotalCategory = useCallback(async () => {
    try {
      const response = await getTotalDataCategory();
      setDataCategory(response?.response);
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    getTotalCategory();
  }, [getTotalCategory]);
  useEffect(() => {
    if (dataCategory) {
      const totalAllDataMaxi = dataCategory?.filter((l) => l?.name === "MAXi");
      const totalAllDataClassy = dataCategory?.filter(
        (l) => l?.name === "Classy"
      );
      const totalAllDataMatic = dataCategory?.filter(
        (l) => l?.name === "Matic"
      );
      const totalAllDataSport = dataCategory?.filter(
        (l) => l?.name === "Sport"
      );
      const totalAllDataOffRoad = dataCategory?.filter(
        (l) => l?.name === "Off-Road"
      );
      const totalAllDataMoped = dataCategory?.filter(
        (l) => l?.name === "Moped/Bebek"
      );

      setTotalDataMaxi(totalAllDataMaxi[0]?.total || 0);
      setTotalDataClassy(totalAllDataClassy[0]?.total || 0);
      setTotalDataMatic(totalAllDataMatic[0]?.total || 0);
      setTotalDataSport(totalAllDataSport[0]?.total || 0);
      setTotalDataOffRoad(totalAllDataOffRoad[0]?.total || 0);
      setTotalDataMoped(totalAllDataMoped[0]?.total || 0);
      setTotalAllData(
        (isValidNumber(totalAllDataMaxi[0]?.total)
          ? totalAllDataMaxi[0]?.total
          : 0) +
          (isValidNumber(totalAllDataClassy[0]?.total)
            ? totalAllDataClassy[0]?.total
            : 0) +
          (isValidNumber(totalAllDataMatic[0]?.total)
            ? totalAllDataMatic[0]?.total
            : 0) +
          (isValidNumber(totalAllDataSport[0]?.total)
            ? totalAllDataSport[0]?.total
            : 0) +
          (isValidNumber(totalAllDataOffRoad[0]?.total)
            ? totalAllDataOffRoad[0]?.total
            : 0) +
          (isValidNumber(totalAllDataMoped[0]?.total)
            ? totalAllDataMoped[0]?.total
            : 0)
      );
    }
  }, [data?.categorys, dataCategory]);

  const categoryMotor = useMemo(() => {
    return [
      {
        category: "Semua",
        value: totalAllData,
      },
      {
        category: "MAXi",
        value: totalDataMaxi || 0,
      },
      {
        category: "Classy",
        value: totalDataClassy || 0,
      },
      {
        category: "Matic",
        value: totalDataMatic || 0,
      },
      {
        category: "Sport",
        value: totalDataSport || 0,
      },
      {
        category: "Off-Road",
        value: totalDataOffRoad || 0,
      },
      {
        category: "Moped/Bebek",
        value: totalDataMoped || 0,
      },
    ];
  }, [
    totalAllData,
    totalDataClassy,
    totalDataMatic,
    totalDataMaxi,
    totalDataMoped,
    totalDataOffRoad,
    totalDataSport,
  ]);

  const listSortPrice = useMemo(() => {
    return ["Semua Harga", "Harga Terendah", "Harga Tertinggi", "Harga Promo"];
  }, []);

  const listSortDisplay = ["12", "20", "28", "36"];
  const listSortAsc = useMemo(() => {
    return ["A-Z", "Z-A"];
  }, []);

  const listradio = useMemo(() => {
    return [
      {
        name: "Sepeda motor",
        value: 37,
      },
      // {
      //   name: "Spareparts",
      //   value: 0,
      // },
    ];
  }, []);

  const [isOpenSortPrice, setIsOpenSortPrice] = useState(false);
  const [isOpenSortAsc, setIsOpenSortAsc] = useState(false);
  const [isOpenSortDisplay, setIsOpenSortDisplay] = useState(false);
  const [activePrice, setActivePrice] = useState(listSortPrice[0]);
  const [activeAsc, setActiveAsc] = useState(listSortAsc[0]);
  const [activeDisplay, setActiveDisplay] = useState(listSortDisplay[0]);
  const [activeRadio, setActiveRadio] = useState(listradio[0]?.name);

  const handleActiveCategory = (category) => {
    setActiveCategories((prev) => {
      let updatedCategories;
      if (category === "Semua") {
        updatedCategories = prev.includes("Semua") ? [] : ["Semua"];
      } else {
        if (prev.includes("Semua")) {
          updatedCategories = [category];
        } else if (prev.includes(category)) {
          updatedCategories = prev.filter((c) => c !== category);
        } else {
          updatedCategories = [...prev, category];
        }
      }
      if (updatedCategories.length === 0) {
        updatedCategories = ["Semua"];
      }

      return updatedCategories;
    });
  };
  const [limit, setLimit] = useState(null);
  const [page, setPage] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  const categoryQuery = activeCategories
    ?.map((cat) => (cat === "Semua" ? "" : `category[]=${cat}`))
    ?.join("&");

  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 12);
  }, [limitQuery, pageQuery]);

  const [minValue, setMinValue] = useState(0.5);
  const [maxValue, setMaxValue] = useState(100);

  const formatPrice = (price) => {
    if (price >= 1000) {
      return `Rp${price / 1000} jt`;
    }
    return `Rp${price} ribu`;
  };

  useEffect(() => {
    query?.get("t") === "spareparts" && setActiveRadio(listradio[1]?.name);
  }, [listradio, pathname, query]);
  useEffect(() => {
    query?.get("promo") === "promo" && setActivePrice(listSortPrice[3]);
  }, [listSortPrice, query]);
  useEffect(() => {
    if (searchQuery) {
      const prevPage = Math.min(data?.totalPages, page);
      navigate(
        `${pathname}?query=motor&price=${
          activePrice === listSortPrice[1]
            ? "ASC"
            : activePrice === listSortPrice[2]
            ? "DESC"
            : activePrice === listSortPrice[3]
            ? "PROMO"
            : ""
        }&limit=${activeDisplay}&page=${prevPage}&search=${debouncedSearchQuery}&${categoryQuery}&rangePrice[start]=${
          minValue * 1_000_000
        }&rangePrice[end]=${maxValue * 1_000_000}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(
        `${pathname}?query=motor&price=${
          activePrice === listSortPrice[1]
            ? "ASC"
            : activePrice === listSortPrice[2]
            ? "DESC"
            : activePrice === listSortPrice[3]
            ? "PROMO"
            : ""
        }&limit=${activeDisplay}&page=${page}&sort=${
          activeAsc === listSortAsc[0] ? "ASC" : "DESC"
        }&${categoryQuery}&rangePrice[start]=${
          minValue * 1_000_000
        }&rangePrice[end]=${maxValue * 1_000_000}`,
        {
          replace: true,
        }
      );
    }
  }, [
    activeAsc,
    activeCategories,
    activeDisplay,
    activePrice,
    categoryQuery,
    data?.totalPages,
    debouncedSearchQuery,
    limit,
    limitQuery,
    listSortAsc,
    listSortPrice,
    maxValue,
    minValue,
    navigate,
    page,
    pathname,
    searchQuery,
  ]);
  useEffect(() => {
    if (activePrice === "Harga Promo") {
      setPage(null);
      setLimit(null);
    }
  }, [activePrice]);
  useEffect(() => {
    window?.scrollTo({
      top: 0,

      behavior: "smooth",
    });
  }, [page]);
  useEffect(() => {
    setPage(1);
  }, [activeCategories, activeRadio, activeAsc, activeDisplay, activePrice]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setPage(1);
    }, 1000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [minValue, maxValue]);
  const [isOpenItems, setIsOpenItems] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isOpenRangePrice, setIsOpenRangePrice] = useState(false);
  const [tempActiveRadio, setTempActiveRadio] = useState(activeRadio);

  const handleSaveItems = () => {
    setActiveRadio(tempActiveRadio);
    setIsOpenItems(false);
  };

  const [tempActiveCategory, setTempActiveCategory] = useState(["Semua"]);

  const handleToggleCategory = (category) => {
    setTempActiveCategory((prev) => {
      let updatedCategories;
      if (category === "Semua") {
        updatedCategories = prev.includes("Semua") ? [] : ["Semua"];
      } else {
        if (prev.includes("Semua")) {
          updatedCategories = [category];
        } else if (prev.includes(category)) {
          updatedCategories = prev.filter((c) => c !== category);
        } else {
          updatedCategories = [...prev, category];
        }
      }
      if (updatedCategories.length === 0) {
        updatedCategories = ["Semua"];
      }

      return updatedCategories;
    });
  };
  const handleSaveCategory = () => {
    setActiveCategories(tempActiveCategory);
    setIsOpenCategory(false);
  };
  const [tempMinValue, setTempMinValue] = useState(minValue);
  const [tempMaxValue, setTempMaxValue] = useState(maxValue);

  const handleSave = () => {
    setMinValue(tempMinValue);
    setMaxValue(tempMaxValue);
    setIsOpenRangePrice(false);
  };
  const refSortPrice = useRef();
  const boxOutsideClick = UseOutsideClick(refSortPrice);

  useEffect(() => {
    if (boxOutsideClick && isOpenSortPrice) {
      setIsOpenSortPrice(false);
    }
  }, [boxOutsideClick, isOpenSortPrice]);
  const refSortAsc = useRef();
  const boxOutsideClickAsc = UseOutsideClick(refSortAsc);

  useEffect(() => {
    if (boxOutsideClickAsc && isOpenSortAsc) {
      setIsOpenSortAsc(false);
    }
  }, [boxOutsideClickAsc, isOpenSortAsc]);
  const refSortDisplay = useRef();
  const boxOutsideClickDisplay = UseOutsideClick(refSortDisplay);
  useEffect(() => {
    if (boxOutsideClickDisplay && isOpenSortDisplay) {
      setIsOpenSortDisplay(false);
    }
  }, [boxOutsideClickDisplay, isOpenSortDisplay]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if (
      debouncedSearchQuery?.length > 1 ||
      activeCategories ||
      activePrice ||
      activeRadio ||
      activeAsc ||
      activeDisplay
    ) {
      setLoadingSearch(true);
    }
    setTimeout(() => {
      setLoadingSearch(false);
    }, 500);
  }, [
    activeCategories,
    activePrice,
    activeRadio,
    debouncedSearchQuery,
    activeDisplay,
    activeAsc,
  ]);
  return (
    <div className={`${Styles.container} flex-column-align-center`}>
      {width <= 1024 && (
        <div className={Styles.wrapperFilMobile}>
          <div className={Styles.filter}>
            <div onClick={() => setIsOpenItems(true)}>
              <span>Jenis Barang</span>
              <Icon icon={"panahbawah"} color={"black"} size={"10"} />
            </div>
            <div onClick={() => setIsOpenCategory(true)}>
              <span>Kategori Motor</span>
              <Icon icon={"panahbawah"} color={"black"} size={"10"} />
            </div>
            <div onClick={() => setIsOpenRangePrice(true)}>
              <span>Harga</span>
              <Icon icon={"panahbawah"} color={"black"} size={"10"} />
            </div>
          </div>
          <div className={Styles.refreshIcon}>
            <form action="">
              <button type="submit">
                <RefreshIcon />
              </button>
            </form>
          </div>
        </div>
      )}
      {isOpenItems && <div className={Styles.backdropContain}></div>}
      {isOpenCategory && <div className={Styles.backdropContain}></div>}
      {isOpenRangePrice && <div className={Styles.backdropContain}></div>}
      <div
        className={Styles.wrapperOpenItems}
        is-open={isOpenItems?.toString()}
      >
        <div className={Styles.contentItems}>
          <div className={Styles.header}>
            <div className={Styles.line}></div>
            <div>
              <i></i>
              <span>Jenis Barang</span>
              <Icon
                icon={"x-mark"}
                className={Styles.closeIcon}
                onClick={() => setIsOpenItems(false)}
              />
            </div>
          </div>
          <div className={Styles.wrapRadio}>
            <div>
              <span>Semua Barang</span>
              <span>{totalAllData}</span>
            </div>
            {listradio?.map((el, idx) => (
              <div className={Styles.childList} key={`list-type-item-${idx}`}>
                <div className={Styles.action}>
                  <Icon
                    icon={
                      tempActiveRadio === el?.name
                        ? "radio_button_checked"
                        : "radio_button_unchecked"
                    }
                    className={Styles.radioCheckbox}
                    onClick={() => setTempActiveRadio(el?.name)}
                  />
                  <span>{el?.name}</span>
                </div>
                {/* <span>{el?.value}</span> */}
                <span>{el?.name !== "Spareparts" ? totalAllData : "0"}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={Styles.buttonItems} onClick={() => handleSaveItems()}>
          <span>Simpan</span>
        </div>
      </div>

      <div
        className={Styles.wrapperOpenCategory}
        is-open={isOpenCategory?.toString()}
      >
        <div className={Styles.contentItems}>
          <div className={Styles.header}>
            <div className={Styles.line}></div>
            <div>
              <i></i>
              <span>Kategori Motor</span>
              <Icon
                icon={"x-mark"}
                className={Styles.closeIcon}
                onClick={() => setIsOpenCategory(false)}
              />
            </div>
          </div>
          <div className={Styles.wrapRadio}>
            {categoryMotor?.map((el, idx) => (
              <div
                className={Styles.childList}
                key={`list-type-category-${idx}`}
              >
                <div className={Styles.action}>
                  <Icon
                    onClick={() => handleToggleCategory(el.category)}
                    icon={
                      tempActiveCategory.includes(el.category)
                        ? "checked"
                        : "checkbox"
                    }
                    className={Styles.radioCheckbox}
                  />
                  <span>{el?.category}</span>
                </div>
                {/* <span>{el?.value}</span> */}
                <span>{el?.value}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          className={Styles.buttonItems}
          onClick={() => handleSaveCategory()}
        >
          <span>Simpan</span>
        </div>
      </div>

      <div
        className={Styles.wrapperOpenRangePrice}
        is-open={isOpenRangePrice?.toString()}
      >
        <div className={Styles.contentItems}>
          <div className={Styles.header}>
            <div className={Styles.line}></div>
            <div>
              <i></i>
              <span>Harga</span>
              <Icon
                icon={"x-mark"}
                className={Styles.closeIcon}
                onClick={() => setIsOpenRangePrice(false)}
              />
            </div>
          </div>
          <div className={Styles.headerRange}>
            <span>Harga</span>
            <div>
              <span>{formatPrice(tempMinValue * 1000)}</span>
              <span>{formatPrice(tempMaxValue * 1000)}</span>
            </div>
          </div>
          <div className={Styles.rangeMobile}>
            <PriceRange
              min={1}
              max={100}
              step={1}
              maxValue={tempMaxValue}
              minValue={tempMinValue}
              setMaxValue={setTempMaxValue}
              setMinValue={setTempMinValue}
            />
            <div className={Styles.buttonPrice}>
              <div className={Styles.btnMin}>
                <span>
                  Min{" "}
                  <input
                    type="number"
                    value={tempMinValue}
                    onChange={(e) => setTempMinValue(e?.target?.value)}
                  />{" "}
                </span>
              </div>
              <span className={Styles.line}></span>
              <div className={Styles.btnMax}>
                <span>
                  Max{" "}
                  <input
                    type="number"
                    value={tempMaxValue}
                    onChange={(e) => setTempMaxValue(e?.target?.value)}
                  />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.buttonItems} onClick={handleSave}>
          <span>Simpan</span>
        </div>
      </div>

      <PageHeader
        primaryText="Semua Produk Kami yang Tersedia"
        secondaryText="Cari motor ataupun sparepart terbaik untuk kamu, mulai atur tampilan filter sesuai kemauan."
      />
      <div className={`${Styles.aligner} flex-column-align-center`}>
        {/* <ListingBanner /> */}
        {/* <ListingFilter data={data} isNotFound={isNotFound} /> */}
        <div
          className={`${Styles.whiteBg} flex-row`}
          style={{ borderRadius: "8px" }}
        >
          <div className={`${Styles.filterSec} flex-column `}>
            <form className={`${Styles.filterHeader} flex-row`}>
              <div
                className={`${Styles.filterTitle} ${Styles.filterCtrl} flex-align-center`}
              >
                <h2>Filter</h2>
              </div>
              <button
                type="submit"
                className={`${Styles.filterRefresh} flex-center`}
              >
                <RefreshIcon className={Styles.refreshIcon} />
              </button>
            </form>

            <div className={Styles.filterContent}>
              <div
                className={`${Styles.filterColCtrl} ${Styles.filterPaddingCtrl}`}
              >
                <h3>Jenis Barang</h3>
                <div className={Styles.listItems}>
                  <div className={Styles.topList}>
                    <span>Semua Barang</span>
                    <strong>{totalAllData}</strong>
                  </div>
                  {listradio?.map((el, idx) => (
                    <div
                      className={Styles.childList}
                      key={`list-type-item-${idx + 1}`}
                    >
                      <div className={Styles.action}>
                        <Icon
                          icon={
                            activeRadio === el?.name
                              ? "radio_button_checked"
                              : "radio_button_unchecked"
                          }
                          className={Styles.radioCheckbox}
                          onClick={() => setActiveRadio(el?.name)}
                        />
                        <span>{el?.name}</span>
                      </div>
                      {/* <span>{el?.value}</span> */}
                      <span>
                        {el?.name !== "Spareparts" ? totalAllData : "0"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={`${Styles.filterColCtrl} ${Styles.filterPaddingCtrl}`}
              >
                <h3>Kategori Motor</h3>
                {categoryMotor?.map((el, idx) => (
                  <div
                    className={Styles.listItems}
                    key={`category-motor-${idx}`}
                  >
                    <div className={Styles.childList}>
                      <div className={Styles.action}>
                        <Icon
                          onClick={() => handleActiveCategory(el.category)}
                          icon={
                            activeCategories.includes(el.category)
                              ? "checked"
                              : "checkbox"
                          }
                          className={Styles.checkboxIcon}
                        />
                        <span>{el?.category}</span>
                      </div>
                      <span>{el?.value}</span>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={`${Styles.filterColCtrl} ${Styles.filterPaddingCtrl}`}
              >
                <h3>Harga</h3>
                <div className={Styles.priceRange}>
                  <div>
                    <span>{formatPrice(minValue * 1000)}</span>
                    <span>{formatPrice(maxValue * 1000)}</span>
                  </div>
                  <div>
                    <PriceRange
                      min={1}
                      max={100}
                      step={1}
                      // onRangeChange={handlePriceRangeChange}
                      maxValue={maxValue}
                      minValue={minValue}
                      setMaxValue={setMaxValue}
                      setMinValue={setMinValue}
                    />
                  </div>
                </div>
                <div className={Styles.buttonPrice}>
                  <div
                    className={Styles.btnMin}
                    // style={
                    //   minValue <= 1
                    //     ? { background: "#DDDDDD", cursor: "not-allowed" }
                    //     : { undefined }
                    // }
                  >
                    <span>
                      Min
                      <input
                        type="number"
                        value={minValue}
                        // disabled={minValue <= 1}
                        onChange={(e) => setMinValue(e?.target?.value)}
                      />{" "}
                    </span>
                  </div>
                  <span className={Styles.untilRange}></span>
                  <div
                    className={Styles.btnMax}
                    // style={
                    //   maxValue > 90
                    //     ? { background: "#DDDDDD", cursor: "not-allowed" }
                    //     : { undefined }
                    // }
                  >
                    <span>
                      Max
                      <input
                        type="number"
                        value={maxValue}
                        // disabled={maxValue >= 90}
                        onChange={(e) => setMaxValue(e?.target?.value)}
                      />{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles.filterMain} `}>
            <div
              className={`${Styles.filterHeader} flex-row`}
              is-mobile={"true"}
            >
              <div
                className={`${Styles.filterRowCtrl} ${Styles.filterCtrl} flex-align-center`}
              >
                <SearchInput
                  // onFocus={() => setSidebar((prev) => !prev)}
                  placeholder={"Cari barang disini"}
                  setValue={setSearchQuery}
                  value={searchQuery}
                />
              </div>
              <div className={Styles.filterRowCtrl} nth={"2"}>
                <div
                  className={`${Styles.filterBtn} flex-between-center`}
                  onClick={() => setIsOpenSortPrice(!isOpenSortPrice)}
                >
                  <label>
                    Sort By: <span>{activePrice}</span>
                  </label>
                  <div
                    className={Styles.chevronPrice}
                    is-open-sort-price={isOpenSortPrice?.toString()}
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
                {isOpenSortPrice && (
                  <div className={Styles.dropdownPrice} ref={refSortPrice}>
                    {listSortPrice?.map((l, idx) => (
                      <span
                        key={`sort-price-${idx}`}
                        className={Styles.list}
                        is-active={(activePrice === l)?.toString()}
                        onClick={() => {
                          setActivePrice(l);
                          setIsOpenSortPrice(!isOpenSortPrice);
                        }}
                      >
                        {l}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className={Styles.filterRowCtrl} nth={"3"}>
                <div
                  className={`${Styles.filterBtn} flex-between-center`}
                  onClick={() => setIsOpenSortAsc(!isOpenSortAsc)}
                >
                  <label>
                    Urutkan: <span>{activeAsc}</span>
                  </label>
                  <div
                    className={Styles.chevronPrice}
                    is-open-sort-asc={isOpenSortAsc?.toString()}
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
                {isOpenSortAsc && (
                  <div className={Styles.dropdownAsc} ref={refSortAsc}>
                    {listSortAsc?.map((l, idx) => (
                      <span
                        key={`sort-asc-${idx}`}
                        className={Styles.list}
                        onClick={() => {
                          setActiveAsc(l);
                          setIsOpenSortAsc(!isOpenSortAsc);
                        }}
                        is-active={(activeAsc === l)?.toString()}
                      >
                        {l}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className={Styles.filterRowCtrl} nth={"4"}>
                <div
                  className={`${Styles.filterBtn} flex-between-center`}
                  onClick={() => setIsOpenSortDisplay(!isOpenSortDisplay)}
                  style={{ borderRadius: "8px" }}
                >
                  <label>
                    Tampilkan: <span>{activeDisplay} Items</span>
                  </label>
                  <div
                    className={Styles.chevronPrice}
                    is-open-sort-display={isOpenSortDisplay?.toString()}
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
                {isOpenSortDisplay && (
                  <div className={Styles.dropdownDisplay} ref={refSortDisplay}>
                    {listSortDisplay?.map((l, idx) => (
                      <span
                        key={`sort-display-${idx}`}
                        className={Styles.list}
                        onClick={() => {
                          setIsOpenSortDisplay(!isOpenSortDisplay);
                          setActiveDisplay(l);
                        }}
                        is-active={(activeDisplay === l)?.toString()}
                      >
                        {l} Items
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`${Styles.filterContent} ${Styles.filterGrid}`}
              style={{
                background: "#fff",
                minHeight: width > 1024 ? "90%" : "670px",
                justifyContent: loadingSearch ? "center" : "flex-start",
              }}
            >
              {activeRadio === listradio[1]?.name ? (
                <EmptyHandler />
              ) : debouncedSearchQuery?.length > 1 && isNotFound ? (
                <EmptyHandler
                  title="Data Tidak Ditemukan"
                  description={` Produk Dengan Nama <strong> ${debouncedSearchQuery} </strong> Tidak Ada, Cari Kata Kunci Lain `}
                />
              ) : isNotFound ? (
                <EmptyHandler
                  title="Data Tidak Ditemukan"
                  description={`Saat ini belum ada barang yang ditambahkan ke daftar tersbut.`}
                />
              ) : (
                <>
                  {loadingSearch ? (
                    <Spinner key={`spinner-products`} />
                  ) : (
                    <>
                      {data?.data?.map((each, idx) => (
                        <div key={idx} className={Styles.gridItem}>
                          <div className={`${Styles.gridBody} flex-center`}>
                            <img
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                              }}
                              src={fileBaseUrl + each?.colors[0]?.imgUrl}
                              alt="motor"
                            />
                          </div>
                          <div
                            className={`${Styles.gridClickable} flex-column`}
                          >
                            <div className={`${Styles.gridHead} flex-between`}>
                              <div className={Styles.itemTitle}>
                                <h4>{each.name}</h4>
                                <label>{each?.category}</label>
                              </div>
                              {each?.promo !== null ? (
                                <div
                                  className={`${Styles.itemDisc} flex-center`}
                                >
                                  <span>{each?.promo}</span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className={`${Styles.gridBody} flex-center`}>
                              <button
                                onClick={() =>
                                  navigate(`detail/${each?.motorId}`)
                                }
                                className={Styles.gridButton}
                              >
                                <span>Detail</span>
                              </button>
                            </div>
                            <div
                              className={`${Styles.gridFoot} flex-between-center`}
                            >
                              <div
                                className={Styles.itemPrice}
                                style={{ alignItems: "center" }}
                              >
                                {each?.promo === null ? (
                                  <>
                                    <h4>
                                      Rp {each?.price?.toLocaleString("id-ID")}
                                    </h4>
                                    <h5 style={{ transform: "scale(0)" }}>
                                      Rp {each?.price?.toLocaleString("id-ID")}
                                    </h5>
                                  </>
                                ) : (
                                  <>
                                    <h4>
                                      Rp{" "}
                                      {each?.newPrice?.toLocaleString("id-ID")}
                                    </h4>
                                    <h5>
                                      Rp {each?.price?.toLocaleString("id-ID")}
                                    </h5>
                                  </>
                                )}
                              </div>
                              <div
                                className={`${Styles.itemColor} flex-reverse-center`}
                              >
                                {each?.colors?.length > 4 && (
                                  <span>+{each?.colors?.length - 4}</span>
                                )}
                                {each?.colors?.slice(0, 4).map((item) => (
                                  <div
                                    key={item.id}
                                    className={Styles.colorBullet}
                                    style={
                                      item?.name?.toLowerCase() === "white" ||
                                      item?.name?.toLowerCase() === "putih" ||
                                      item?.name
                                        ?.toLowerCase()
                                        ?.includes("white") ||
                                      item?.name
                                        ?.toLowerCase()
                                        ?.includes("putih")
                                        ? {
                                            border: "0.2px solid #2B2B2B",
                                            background: item?.code,
                                            // background:
                                            //   "linear-gradient(244deg, #D9D9D9 7%, #bcb1b1 51.29%, #D9D9D9 93%)",
                                          }
                                        : { background: item?.code }
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
              {data?.totalData < 12 &&
                !loadingSearch &&
                Array.from(
                  { length: Math.max(0, limit - (data?.totalData || 0)) },
                  (_, k) => k
                ).map((each) => (
                  <div key={each} className={`${Styles.gridItem}`}></div>
                ))}
            </div>
          </div>
        </div>
        <div className={`flex ${Styles.wrapeperPagination}`}>
          <div className={Styles.seperators}></div>
          <div className={`${Styles.filterFooter} flex-row`}>
            <button
              onClick={() => page > 1 && setPage((prev) => prev - 1)}
              className={`${Styles.filterRowCtrl} ${Styles.filterCtrl} ${Styles.filterPageBtn} flex-center`}
              style={page === 1 ? { cursor: "not-allowed" } : { undefined }}
            >
              <ChevronLeftIcon color={page === 1 ? "#DDDDDD" : "#357ABB"} />
            </button>
            <div
              className={`${Styles.filterRowCtrl} ${Styles.filterCtrl} flex-center`}
            >
              <ListingPagination
                page={activeRadio === listradio[1]?.name ? 1 : page}
                setPage={setPage}
                maxPage={
                  activeRadio === listradio[1]?.name ? 1 : data?.totalPages
                }
              />
            </div>
            <button
              onClick={
                page === data?.totalPages
                  ? () => {}
                  : () => page < data?.totalPages && setPage((prev) => prev + 1)
              }
              className={`${Styles.filterRowCtrl} ${Styles.filterCtrl} ${Styles.filterPageBtn} flex-center`}
              style={
                page === data?.totalPages
                  ? { cursor: "not-allowed" }
                  : { undefined }
              }
            >
              <ChevronRightIcon
                color={page === data?.totalPages ? "#DDDDDD" : "#357ABB"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
