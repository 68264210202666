import NavbarLandingPage from "@Molecule/NavbarLandingPage";
import "./App.scss";
import { defer, Outlet } from "react-router-dom";
import { getCategoryMotor } from "@Services/getCategoryLandingPage";
import { useEffect, useState } from "react";

export function loader(route) {
  const { id } = route?.params;
  return defer({
    getDataCategory: getCategoryMotor(id),
  });
}
function App() {
  const [isActive, setIsActive] = useState(0);
  const [dataCategory, setDataCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getCategoryMotor(isActive + 1)
      .then((data) => {
        setDataCategory(data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [isActive]);
  return (
    <NavbarLandingPage
      isActive={isActive}
      setIsActive={setIsActive}
      data={dataCategory}
      isLoading={loading}
    >
      <Outlet />
    </NavbarLandingPage>
  );
}

export default App;
