import Styles from "./styles.module.scss";
import React, { useMemo, useState } from "react";

import Icon from "@Atom/Icon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "themes/Images/images";
const Footer = () => {
  const [dataEmail, setDataEmail] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setDataEmail({ ...dataEmail, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(dataEmail.email);
  };
  const navigation = useMemo(() => {
    return [
      {
        text: "Produk",
        link: "/products",
      },
      {
        text: "Promo",
        link: "products?promo=promo",
      },
      {
        text: "Price List",
        // link: "",
      },
    ];
  }, []);
  const information = useMemo(() => {
    return [
      {
        text: "Privacy Policy",
        // link: "/privacy-policy",
      },
      {
        text: "Cookies",
        // link: "/cookies",
      },
      {
        text: "Terms and Conditions",
        // link: "/terms",
      },
    ];
  }, []);
  const socialMedia = useMemo(() => {
    return [
      {
        icon: "instagram",
        link: "https://www.instagram.com/permatayamaha",
      },
      {
        icon: "facebook",
        link: "https://facebook.com/permata.yamaha.5/?_rdc=1&_rdr",
      },
      {
        icon: "youtube",
        link: "https://www.youtube.com/@permatamotorkebayoran398",
      },
    ];
  }, []);
  const navigate = useNavigate();
  const openMaps = () => {
    const url =
      "https://www.google.com/maps/place/Jl. Raya Kby. Lama No.17A, RT.1/RW.11, Grogol Sel., Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220";
    window.open(url, "_blank");
  };
  const { pathname, search } = useLocation();
  return (
    <div
      className={Styles.wrapperFooter}
      mobile-list-products={
        pathname.includes("products") && search.startsWith("?query")
          ? "true"
          : "false"
      }
    >
      <div className={Styles.information}>
        <div className={Styles.address}>
          <img src={Images.FOOTER_LOGO_TEXT} alt="logo" />
          <div>
            <span>Alamat</span>
            <p onClick={() => openMaps()}>
              Jl. Raya Kby. Lama No.17A, RT.1/RW.11, Grogol Sel., Kec. Kby.
              Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220
            </p>
          </div>
        </div>
        <div className={Styles.flexContain}>
          <div className={Styles.navigation}>
            <h2>Navigasi</h2>
            {navigation?.map((list, idx) => (
              <div className={Styles.listMenu} key={idx}>
                <span onClick={() => navigate(list?.link)}>{list?.text}</span>
              </div>
            ))}
          </div>
          <div className={Styles.informationApp}>
            <h2>Informasi</h2>
            {information?.map((list, idx) => (
              <div className={Styles.listMenu} key={idx}>
                <span onClick={() => navigate(list?.link)}>{list?.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={Styles.mailBox} style={{ display: "none" }}>
        <h3>Berita Terbaru</h3>
        <div className={Styles.text}>
          <div>
            Tulis email anda disini untuk mendapatkan berita terbaru dari kami
            langsung ke dalam inbox anda
          </div>
          <span>
            Dengan mengirimkan email, anda menerima terms dan conditions kami
          </span>
        </div>
        <div className={Styles.buttonSend}>
          <input
            type="email"
            placeholder="Email Address"
            className={Styles.input}
            onChange={handleChange}
            name="email"
          />
          <div className={Styles.iconNext} onClick={handleSubmit}>
            <Icon icon={"arrow-right"} className={Styles.icon} />
          </div>
        </div>
      </div>
      <div className={Styles.socialMedia}>
        {socialMedia?.map((item, idx) => (
          <Link to={item?.link} key={idx}>
            <Icon key={idx} icon={item.icon} className={Styles.icon} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
