import React, { useEffect, useMemo, useState } from "react";
import Styles from "./styles.module.scss";
import { ImageMagnifier } from "components/Atom";
import MONEY_PRIMARY from "assets/img/money-primary.png";
// import HintInfoIcon from "assets/svg/hintInfo";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images/images";
import ImgIconMotor from "@Assets/img/motorcycle-white.png";
import { fileBaseUrl } from "@Config/index";
import useWindowSize from "@Hooks/useWindowSize";
// import useWindowSize from "@Hooks/useWindowSize";

export default function ViewerSimulation({ data }) {
  const linkVideo = data?.Files?.filter((el) => el?.url?.includes("https://"));
  const dataEngine = data?.Specifications?.filter(
    (el) => el?.title === "Spesifikasi Mesin"
  );
  const dataFrame = data?.Frames?.filter((el) => el?.title === "Rangka");
  const dataDimension = data?.Dimensions?.filter(
    (el) => el?.title === "Dimensi"
  );
  const dataElectricity = data?.Electricities?.filter(
    (el) => el?.title === "Kelistrikan"
  );
  const accordionHead = useMemo(() => {
    return [
      {
        title: "Mesin",
        icon: "Engine",
        image: Images.ENGINE_DETAIL,
        paragraph: "Lihat detail spesifikasi mesin disini",
        data: dataEngine,
      },
      {
        title: "Rangka",
        icon: "Rangka",
        paragraph: "Lihat detail spesifikasi rangka disini",
        image: Images.FRAME_DETAIL,
        data: dataFrame,
      },
      {
        title: "Dimensi",
        icon: "Dimension",
        image: Images.DIMENSION_DETAIL,
        paragraph: "Lihat detail spesifikasi dimensi disini",
        data: dataDimension,
      },
      {
        title: "Kelistrikan",
        icon: "Electrical",
        image: Images.ELECTRICAL_DETAIL,
        paragraph: "Lihat detail spesifikasi kelistrikan disini",
        data: dataElectricity,
      },
    ];
  }, [dataDimension, dataElectricity, dataEngine, dataFrame]);
  const tabs = ["Fitur", "Spesifikasi", "Video"];
  // const calculatePercentage = ["10", "20", "30", "40", "50"];
  // const calculateTenor = ["11", "23", "35"];
  const [activeTabs, setActiveTabs] = useState(tabs[0]);
  const [openAccordions, setOpenAccordions] = useState([0]);
  const handleOpenAccordion = (idx) => {
    setOpenAccordions((prev) =>
      prev.includes(idx) ? prev.filter((i) => i !== idx) : [...prev, idx]
    );
  };

  const [activeVariants, setActiveVariants] = useState(0);
  const [activeColors, setActiveColors] = useState(0);
  // const [isOpenDrpPercent, setIsOpenPercent] = useState(false);
  // const [activePercent, setActivePercent] = useState(calculatePercentage[0]);
  // const [isOpenDrpTenor, setIsOpenTenor] = useState(false);
  // const [activeTenor, setActiveTenor] = useState(null);
  const [isErrorVideo, setIsErrorVideo] = useState(false);
  const promo =
    data?.Variants[activeVariants]?.PromoVariants?.length === 0 ||
    data?.Variants[activeVariants]?.PromoVariants[0]?.Promo?.discount ===
      null ||
    undefined ||
    "" ||
    0;
  const { width } = useWindowSize();
  useEffect(() => {
    setActiveColors(0);
  }, [activeVariants]);
  return (
    <section className={Styles.wrapperDetail}>
      {width <= 1024 ? (
        <>
          <div className={Styles.viewerResponsive}>
            {!promo ? (
              <div className={Styles.discount}>
                <span>
                  {"-" +
                    data?.Variants[activeVariants]?.PromoVariants[0]?.Promo
                      ?.discount}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className={Styles.imgMagnifier}>
              <img
                src={
                  fileBaseUrl +
                  data?.Variants[activeVariants]?.VariantColors[activeColors]
                    ?.imgUrl
                }
                alt="motor"
              />
              <div className={Styles.motorColor}>
                {data?.Variants[activeVariants || 0]?.VariantColors?.map(
                  (c, idx) => (
                    <button
                      key={idx}
                      style={
                        c?.name?.toLowerCase() === "white" ||
                        c?.name?.toLowerCase() === "putih" ||
                        c?.name?.toLowerCase().includes("white") ||
                        c?.name?.toLowerCase().includes("putih")
                          ? {
                              background:
                                "linear-gradient(244deg, #fff 7%, #e8e1e1 51.29%, #d6d2d2 93%)",
                              // outline: "1px solid black",
                            }
                          : { background: c?.code }
                      }
                      is-active-color={(activeColors === idx)?.toString()}
                      onClick={() => setActiveColors(idx)}
                    ></button>
                  )
                )}
              </div>
            </div>
            <div className={Styles.variant}>
              <h4>Variant Motor</h4>

              <div className={Styles.wrapVariant}>
                {data?.Variants?.map((el, idx) => (
                  <div
                    className={Styles.nameVariant}
                    key={idx}
                    onClick={() => setActiveVariants(idx)}
                    style={{ flexShrink: "0" }}
                    is-active-variant={(activeVariants === idx)?.toString()}
                  >
                    <img
                      src={fileBaseUrl + el?.VariantColors[0]?.imgUrl}
                      alt="variant-images"
                    />
                    <span>{el?.name} </span>
                  </div>
                ))}
              </div>
            </div>
            <div className={Styles.description}>
              <div className={Styles.nameDesc}>
                <h4>{data?.name} </h4>
                <span>Varian</span>
                <button>
                  <span>{data?.Variants[activeVariants]?.name} </span>
                </button>
              </div>
              {data?.Variants[activeVariants]?.totalPrice !== null ||
              undefined ? (
                <div className={Styles.price}>
                  <h4>
                    {`Rp${data?.Variants[
                      activeVariants
                    ]?.totalPrice?.toLocaleString("id-ID")}`}
                  </h4>
                  <span>
                    {`Rp${data?.Variants[activeVariants]?.price?.toLocaleString(
                      "id-ID"
                    )}`}
                  </span>
                </div>
              ) : (
                <div className={Styles.price}>
                  <h4>
                    {`Rp${data?.Variants[activeVariants]?.price?.toLocaleString(
                      "id-ID"
                    )}`}
                  </h4>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={Styles.viewerGrid}>
          <div
            className={`${Styles.viewerVariant} flex-column`}
            style={{
              height: "100vh",
              overflow: "scroll",
            }}
          >
            {data?.Variants?.map((el, idx) => (
              <button
                className={Styles.itemVariant}
                key={idx}
                onClick={() => setActiveVariants(idx)}
                style={{ flexShrink: "0" }}
                is-active-variant={(activeVariants === idx)?.toString()}
              >
                <div className={`${Styles.variantTag}`}>
                  <span>{el?.name}</span>
                </div>
                <img
                  src={fileBaseUrl + el?.VariantColors[0]?.imgUrl}
                  alt="detail-motor"
                  className={Styles.variantImg}
                />
              </button>
            ))}

            <div className={Styles.emptySpace}></div>
          </div>
          <div className={Styles.viewerContent}>
            <div
              className={`${Styles.variantContent} flex-column-align-center`}
            >
              <div
                className={`${Styles.variantColors} flex-column-align-center`}
              >
                {data?.Variants[activeVariants || 0]?.VariantColors?.map(
                  (c, idx) => (
                    <button
                      style={{ background: c?.code }}
                      className={Styles.colorSquare}
                      key={idx}
                      is-active-color={(activeColors === idx)?.toString()}
                      onClick={() => setActiveColors(idx)}
                    >
                      <div className={Styles.toolTip}>
                        <span>{c?.name}</span>
                        <Icon
                          icon={"Popover"}
                          color={"#363535"}
                          size={"19"}
                          className={Styles.popover}
                        />
                      </div>
                    </button>
                  )
                )}
              </div>
              <ImageMagnifier
                width="565px"
                height="488px"
                src={
                  fileBaseUrl +
                  data?.Variants[activeVariants]?.VariantColors[activeColors]
                    ?.imgUrl
                }
                // src={fileBaseUrl + imageMagnifier}
              />
              {!promo ? (
                <div className={`${Styles.variantDiscount} flex-center`}>
                  <span>
                    -
                    {
                      data?.Variants[activeVariants]?.PromoVariants[0]?.Promo
                        ?.discount
                    }
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className={`${Styles.variantBody} flex-column`}>
                <h1>{data?.name}</h1>
                <label>Varian</label>
                <div className={Styles.variantTag}>
                  <span>{data?.Variants[activeVariants]?.name}</span>
                </div>
                <div className={`${Styles.variantPrice} flex-column`}>
                  {data?.Variants[activeVariants]?.totalPrice !== null ||
                  undefined ? (
                    <>
                      <div className={Styles.finalPrice}>
                        <span>
                          {`Rp${data?.Variants[
                            activeVariants
                          ]?.totalPrice?.toLocaleString("id-ID")}`}
                        </span>
                      </div>
                      <div className={Styles.basePrice}>
                        <span>
                          {`Rp${data?.Variants[
                            activeVariants
                          ]?.price?.toLocaleString("id-ID")}`}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className={Styles.finalPrice}>
                      <span>
                        {`Rp${data?.Variants[
                          activeVariants
                        ]?.price?.toLocaleString("id-ID")}`}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles.viewerSimulation} flex-column`}>
            {/* <div className={`${Styles.simHint} flex-align-center`}>
            <HintInfoIcon />
            <span>
              Cek estimasi pembiayaan kredit motor dengan kalkulator Permata
              Motor
            </span>
          </div> */}

            <div className={`${Styles.simInfo} flex-column`}>
              <div className={`${Styles.infoCtrl} flex-align-center`}>
                <img src={ImgIconMotor} alt="logo" title="motor" />
                <div className={Styles.infoDivider}></div>
                <div className={Styles.infoContent}>
                  <label>Varian</label>
                  <p>{data?.Variants[activeVariants]?.name}</p>
                </div>
              </div>
              <div className={`${Styles.infoCtrl}  flex-align-center`}>
                <img src={MONEY_PRIMARY} alt="" />
                <div className={Styles.infoDivider}></div>
                <div className={Styles.infoContent}>
                  <label>Harga</label>
                  {!promo ? (
                    <p>
                      {data?.Variants[
                        activeVariants
                      ]?.totalPrice?.toLocaleString("id-ID")}
                    </p>
                  ) : (
                    <p>
                      {data?.Variants[activeVariants]?.price?.toLocaleString(
                        "id-ID"
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* <div className={Styles.calculateWrapper}>
            <div>
              <h5>Uang Muka & Tenor</h5>
              <div className={Styles.wrapPercentAndPrice}>
                <div
                  className={Styles.dropdownPercentage}
                  onClick={() => setIsOpenPercent(!isOpenDrpPercent)}
                >
                  <span>{activePercent}</span>
                  <Icon
                    icon={"panahbawah"}
                    className={Styles.chevron}
                    is-open={isOpenDrpPercent?.toString()}
                  />
                  <div className={Styles.divider}></div>
                  <span>%</span>
                  {isOpenDrpPercent && (
                    <div className={Styles.wrapOpenDrp}>
                      <ul>
                        {calculatePercentage?.map((l, idx) => (
                          <li
                            is-active={(activePercent === l)?.toString()}
                            key={`list-${idx}`}
                            onClick={() => setActivePercent(l)}
                          >
                            {l}%
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className={Styles.inputPrice}>
                  <span>RP</span>
                  <div>|</div>
                  <input type="text" placeholder="Harga" />
                </div>
              </div>
            </div>

            <div
              className={Styles.dropdownTenor}
              is-open-drp-tenor={"true"}
              onClick={() => setIsOpenTenor(!isOpenDrpTenor)}
            >
              <span>
                {activeTenor !== null ? activeTenor + " Bulan" : "Pilih Tenor"}
              </span>
              <Icon
                icon={"panahbawah"}
                className={Styles.chevronTenor}
                is-open={isOpenDrpTenor?.toString()}
              />
              {isOpenDrpTenor && (
                <div className={Styles.wrapOpenDrpTenor}>
                  <ul>
                    {calculateTenor?.map((l, idx) => (
                      <li
                        is-active={(activeTenor === l)?.toString()}
                        key={`list-${idx}`}
                        onClick={() => setActiveTenor(l)}
                      >
                        {l} Bulan
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div> */}

            {/* <div className={Styles.simulationButton} onClick={onClickCalculate}>
            <span>Simulasi</span>
          </div> */}
          </div>
        </div>
      )}
      <div className={Styles.wrapperTabs}>
        {tabs?.map((t, idx) => (
          <div
            className={Styles.tab}
            key={`tab-${idx}`}
            onClick={() => setActiveTabs(t)}
            is-active={(activeTabs === t)?.toString()}
          >
            <span>{t}</span>
          </div>
        ))}
      </div>
      {activeTabs === tabs[0] ? (
        <div className={Styles.mainContent}>
          {data?.Features?.map((el, idx) => (
            <div className={Styles.content} key={`feature-${idx}`}>
              <div className={Styles.img}>
                <img src={fileBaseUrl + el?.imageUrl} alt={el?.title} />
              </div>
              <div className={Styles.paragraph}>
                <h3>{el?.title}</h3>
                <p>{el?.description}</p>
              </div>
            </div>
          ))}
        </div>
      ) : activeTabs === tabs[1] ? (
        <div className={Styles.mainContent}>
          {accordionHead?.map((el, idx) => (
            <div className={Styles.wrapperSpec} key={`header-accordion-${idx}`}>
              <div className={Styles.accordionSpec}>
                <div className={Styles.header}>
                  <img src={el?.image} alt="spec" />
                  <div className={Styles.wrapperHead}>
                    <div className={Styles.rowHead}>
                      <div className={Styles.headAndIcon}>
                        <strong>{el?.title}</strong>
                        <Icon
                          icon={el?.icon}
                          color={"#357ABB"}
                          size={width <= 1024 ? "16" : "24"}
                        />
                      </div>
                      <p>{el?.paragraph}</p>
                    </div>
                    <Icon
                      icon={openAccordions.includes(idx) ? "minus" : "plus"}
                      size={openAccordions.includes(idx) ? "32" : "20"}
                      onClick={() => handleOpenAccordion(idx)}
                      className={Styles.iconAccordion}
                    />
                  </div>
                </div>
                <div
                  className={Styles.divider}
                  style={
                    openAccordions.includes(idx)
                      ? { display: "inherit" }
                      : { display: "none" }
                  }
                ></div>
                <section
                  className={Styles.wrapperDescription}
                  is-open-accordion={
                    openAccordions.includes(idx) ? "true" : "false"
                  }
                  key={`list-accordion-${idx}`}
                >
                  <div className={Styles.desc}>
                    <div className={Styles.headDesc}>
                      <h4>Jenis</h4>
                      <h4>Deskripsi</h4>
                    </div>

                    <div className={Styles.decLabelAndValue}>
                      {el?.data?.map((li, idx) => (
                        <>
                          {li?.label && li?.value === "" ? (
                            ""
                          ) : (
                            <ul className={Styles.wrapList}>
                              <li key={`list-label-accordion-${idx}`}>
                                {li?.label}
                              </li>
                              <li key={`list-value-accordion-${idx}`}>
                                {li?.value}
                              </li>
                            </ul>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={Styles.mainContent}>
          {linkVideo?.map((el, idx) => (
            <div className={Styles.iframeYoutube} key={`video-${idx}`}>
              {isErrorVideo ? (
                <h1>Error</h1>
              ) : (
                <iframe
                  onError={() => setIsErrorVideo(true)}
                  width={width <= 1024 ? "100%" : "1175px"}
                  height={width <= 1024 ? "100%" : "665px"}
                  src={el?.url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          ))}
        </div>
      )}
    </section>
  );
}
