import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import { ProductViewer } from "@Organism/index";
import { getDetailMotor } from "@Services/getDetailMotor";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Await, defer, useLoaderData } from "react-router-dom";
export function loader(route) {
  const { id } = route?.params;
  return defer({
    getData: getDetailMotor(id),
  });
}
export default function Detail() {
  const { getData } = useLoaderData();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <Helmet>
        <meta name="description" content="Aerox 155 | Permata x Yamaha" />
        <title>Aerox 155 | Permata x Yamaha</title>
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <Await
          resolve={getData}
          errorElement={<EmptyHandlerGetData />}
          children={(value) => <ProductViewer data={value} />}
        ></Await>
      </Suspense>
    </div>
  );
}
