import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import { ProductListing } from "@Organism/index";
import { getDataMotor as getAllMotor } from "@Services/getDataLandingPage";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Await, defer, useLoaderData } from "react-router-dom";
export function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || null;
  const limit = params?.get("limit") || null;
  const price = params?.get("price") || "";

  const sort = params?.get("sort") || "DESC";
  const rangePriceStart = params?.get("rangePrice[start]") || 0;
  const rangePriceEnd = params?.get("rangePrice[end]") || 0;
  const searchQuery = params?.get("search") || "";
  const categories = [];
  params.forEach((value, key) => {
    if (key === "category[]" || key === "category") {
      categories.push(value);
    }
  });

  return defer({
    getAllMotor: getAllMotor(
      price,
      limit,
      page,
      searchQuery,
      categories,
      sort,
      rangePriceStart,
      rangePriceEnd
    ),
  });
}
export default function ListProducts() {
  const { getAllMotor } = useLoaderData();

  return (
    <div style={{ overflowX: "clip" }}>
      <Helmet>
        <meta
          name="description"
          content="Cari motor ataupun sparepart terbaik untuk kamu, mulai atur tampilan filter sesuai kemauan."
        />
        <title>
          Cari Motor Ataupun Sparepart Terbaik Untuk Kamu, Mulai Atur Tampilan
          Filter Sesuai Kemauan.
        </title>
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <Await resolve={getAllMotor} errorElement={<EmptyHandlerGetData />}>
          {(value) => {
            return (
              <ProductListing
                data={value?.response}
                isNotFound={value?.message === "No data found"}
              />
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
}
