import React from "react";
import Styles from "./style.module.scss";

export default function PriceRange({
  min = 0,
  max = 200,
  step = 1,
  onRangeChange = () => {},
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
}) {
  const handleMinChange = (event) => {
    const value = Math.min(Number(event.target.value), maxValue - step);
    setMinValue(value);
    onRangeChange([value, maxValue]);
  };

  const handleMaxChange = (event) => {
    const value = Math.max(Number(event.target.value), minValue + step);
    setMaxValue(value);
    onRangeChange([minValue, value]);
  };
  const preventClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div className={Styles.rangeContainer}>
      <div className={Styles.sliderContainer}>
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={minValue}
          onChange={handleMinChange}
          className={`${Styles.slider} ${Styles.sliderMin}`}
          style={{
            background: `linear-gradient(to right, #D7E4F1 ${minValue}%, #357ABB ${minValue}%)`,
          }}
          onClick={preventClick}
        />
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={maxValue}
          onChange={handleMaxChange}
          className={`${Styles.slider} ${Styles.sliderMax}`}
          // style={{
          //   background: `linear-gradient(to right,#357ABB ${maxValue}%,  #D7E4F1 ${maxValue}%)`,
          // }}
          onClick={preventClick}
        />
      </div>
    </div>
  );
}
