import React, { useRef, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

const Modal = ({ children, handleDone, disableMoveToEnd, ...props }) => {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollEvent = (e) => {
    if (e.target.scrollTop >= 1) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const renderChildren = () => {
    return React.cloneElement(children, {
      isScrolling: isScrolling,
    });
  };

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  return (
    <div className={`${Styles.background} ${!disableMoveToEnd ? Styles.moveToEnd : Styles.reduceWidth}`}>
      <div
        ref={boxRef}
        className={Styles.modalContainer}
        onScroll={scrollEvent}
        style={{ width: props?.width ? props?.width : '' }}
      >
        {renderChildren()}
      </div>
    </div>
  );
};

export default Modal;
