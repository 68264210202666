import Styles from "./styles.module.scss";
export const Advantage = () => {
  return (
    <div className={Styles.boxAdvantage}>
      <div className={Styles.boxFirst}>
        <span className={Styles.titleAdvantage}>RESMI</span>
        <span className={Styles.ketAdvantage}>
          Showroom dan bengkel resmi. Mendapatkan Support langsung dari Yamaha
          Indonesia memberikan pelayanan terbaik kepada pelanggan
        </span>
      </div>
      <div className={Styles.boxSecond}>
        <span className={Styles.titleAdvantage}>TERPERCAYA</span>
        <span className={Styles.ketAdvantage}>
          Showroom dan bengkel resmi. Mendapatkan Support langsung dari Yamaha
          Indonesia memberikan pelayanan terbaik kepada pelanggan
        </span>
      </div>
      <div className={Styles.boxThird}>
        <span className={Styles.titleAdvantage}>HARGA TERBAIK</span>
        <span className={Styles.ketAdvantage}>
          Showroom dan bengkel resmi. Mendapatkan Support langsung dari Yamaha
          Indonesia memberikan pelayanan terbaik kepada pelanggan
        </span>
      </div>
      <div className={Styles.boxFourth}>
        <span className={Styles.titleAdvantage}>MUDAH & CEPAT</span>
        <span className={Styles.ketAdvantage}>
          Showroom dan bengkel resmi. Mendapatkan Support langsung dari Yamaha
          Indonesia memberikan pelayanan terbaik kepada pelanggan
        </span>
      </div>
    </div>
  );
};
