import { API } from "@Config/index";

export const getCategoryMotor = (categoryID = 5) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/motor/category/${categoryID}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
