import React from "react";
import Styles from "./styles.module.scss";
import PageHeader from "components/Molecule/PageHeader";
import ViewerSimulation from "components/Molecule/ViewerSimulation";
// import Icon from "@Atom/Icon";
// import OldMotor from "@Assets/img/Motorcycle-old.png";
// import Modal from "@Atom/Modal";
export default function ProductViewer({ data }) {
  // const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <div className={`${Styles.container} flex-column-align-center`}>
      {/* {isOpenModal && (
        <Modal width="376px" handleDone={() => setIsOpenModal()}>
          <div className={Styles.wrapModalSimulation}>
            <div className={Styles.header}>
              <div>
                <div className={Styles.wrapLogo}>
                  <img src={OldMotor} alt="logo-motor" />
                </div>
                <span>PERMATA MOTOR</span>
              </div>
              <Icon
                icon={"x-cross1"}
                color={"black"}
                size={"16"}
                className={Styles.closeIcon}
                onClick={() => setIsOpenModal(!isOpenModal)}
              />
            </div>
            <div className={Styles.contentModal}>
              <div className={Styles.childHead}>
                <h5>Simulasi Angsuran</h5>
              </div>
              <div className={Styles.wrapperlist}>
                <div className={Styles.label}>
                  <ul>
                    <li>Motor</li>
                    <li>Tahun</li>
                    <li>Harga</li>
                    <li>Uang Muka</li>
                    <li>Tenor</li>
                    <li>Angsuran Perbulan</li>
                  </ul>
                </div>
                <div className={Styles.value}>
                  <ul>
                    <li>: Nmax</li>
                    <li>: 2024</li>
                    <li>: Rp27.000.000</li>
                    <li>: Rp4.0000.000 (7%)</li>
                    <li>: 12 Bulan</li>
                    <li>: Rp1.500.000</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={Styles.buttonModal}>
              <Icon icon={"wa"} className={Styles.iconWhatsApp} size={"20"} />
              <span>Hubungi Kami</span>
            </div>
          </div>
        </Modal>
      )} */}
      <PageHeader
        primaryText="Lihat Semua Daftar Produk"
        secondaryText="Cari motor ataupun sparepart terbaik untuk kamu, mulai atur tampilan filter sesuai kemauan."
      />
      <div className={`${Styles.aligner} flex-column-align-center`}>
        <ViewerSimulation data={data} />
      </div>
    </div>
  );
}
