import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import LOGO from "@Assets/img/PermataMotorLogo.png";
import LOGO_TEXT from "@Assets/img/PermataMotorText.png";
import LOGO_TEXT_BLACK from "@Assets/img/PermataMotorTextBlack.png";
import YAMAHA_LOGO from "@Assets/img/yamaha-logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import LoginShow from "@Molecule/Modal/Login";
import RegisterShow from "@Molecule/Modal/Register";
import { Footer } from "@Organism/index";
import ButtonWhatsApp from "@Atom/WhatsappButton";
import Images from "@Theme/Images/images";
import { fileBaseUrl } from "@Config/index";
import Spinner from "@Atom/Spinner";

const NavbarLandingPage = ({
  children,
  data,
  isActive,
  setIsActive = () => {},
  isLoading = false,
  // isOpenMenuMotor,
  // setIsOpenMenuMotor = () => {},
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [openProduct, setOpenProduct] = useState(false);
  const [openProductResponsive, setOpenProductResponsive] = useState(false);
  const [openResponsive, setOpenResponsive] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLogin, setIsLogin] = useState(true);
  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const [openUserDropdownResponsive, setOpenUserDropdownResponsive] =
    useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  function closeDropdown() {
    setIsLogin(false);
    if (openProduct) {
      setOpenProduct(false);
    }
    if (openUserDropdown) {
      setOpenUserDropdown(false);
    }
  }
  const navigate = useNavigate();
  const [isOpenMenuMotor, setIsOpenMenuMotor] = useState(false);
  const tabsMenu = useMemo(() => {
    return [
      {
        iconActive: Images.MAXI_WHITE_LOGO,
        icon: Images.MAXI_BLUE_LOGO,
        name: "MAXi",
      },
      {
        iconActive: Images.CLASSY_WHITE_LOGO,
        icon: Images.CLASSY_BLUE_LOGO,
        name: "Classy",
      },
      {
        iconActive: Images.MATIC_WHITE_LOGO,
        icon: Images.MATIC_BLUE_LOGO,
        name: "Matic",
      },
      {
        iconActive: Images.SPORT_WHITE_LOGO,
        icon: Images.SPORT_BLUE_LOGO,
        name: "Sport",
      },
      {
        iconActive: Images.OFF_ROAD_WHITE_LOGO,
        icon: Images.OFF_ROAD_BLUE_LOGO,
        name: "Off-Road",
      },
      {
        iconActive: Images.MOPED_WHITE_LOGO,
        icon: Images.MOPED_BLUE_LOGO,
        name: "Moped",
      },
    ];
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardRefs = useRef([]);
  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    cardRefs.current[currentIndex + 1]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      cardRefs.current[currentIndex - 1]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  useEffect(() => {
    cardRefs.current.forEach((ref, index) => {
      if (ref) cardRefs.current[index] = null;
      setCurrentIndex(0);
    });
  }, [isActive]);
  useEffect(() => {
    setIsOpenMenuMotor(false);
  }, [pathname]);
  return (
    <>
      <ButtonWhatsApp />
      <div
        className={`${Styles.bodyNavbarBox} ${Styles.darkBackground} ${
          (pathname === `/products/detail/${id}` && Styles.darkBackground) ||
          (pathname === `/products` && Styles.darkBackground)
        }`}
        // style={
        //   pathname === "/" ? { position: "fixed" } : { position: "sticky" }
        // }
      >
        <div className={Styles.navbarBox}>
          {isOpenMenuMotor && <div className={Styles.backdrop}></div>}
          <section
            className={Styles.wrapperMenuMotor}
            is-open-menu={isOpenMenuMotor?.toString()}
            style={{ justifyContent: "space-between" }}
          >
            <div className={Styles.tabsLogoMotor}>
              {tabsMenu?.map((el, idx) => (
                <div
                  className={Styles.logoIcon}
                  key={idx}
                  is-active={(isActive === idx)?.toString()}
                  onClick={() => setIsActive(idx)}
                >
                  <img
                    src={isActive === idx ? el?.iconActive : el?.icon}
                    alt={el?.name}
                  />
                  <span>{el?.name}</span>
                </div>
              ))}
            </div>
            {isLoading && <Spinner />}
            <div
              className={Styles.carouselProducts}
              is-open={isOpenMenuMotor?.toString()}
            >
              <aside className={Styles.wrapperChevron}>
                <div
                  className={Styles.chevronLeft}
                  onClick={currentIndex === 0 ? () => {} : () => handlePrev()}
                  is-disabled={(currentIndex === 0)?.toString()}
                >
                  <Icon icon={"chevron-left"} className={Styles.icon} />
                </div>
                <div
                  className={Styles.chevronRight}
                  onClick={
                    data?.length <= 4 || currentIndex === data?.length - 4
                      ? () => {}
                      : () => handleNext()
                  }
                  is-disabled={
                    data?.length <= 4
                      ? "true"
                      : currentIndex === data?.length - 4
                      ? "true"
                      : "false"
                  }
                >
                  <Icon icon={"chevron-right"} className={Styles.icon} />
                </div>
              </aside>
              {data?.map((el, idx) =>
                isLoading ? (
                  ""
                ) : (
                  <div
                    className={`flex-column ${Styles.card}`}
                    key={idx}
                    ref={(el) => (cardRefs.current[idx] = el)}
                    onClick={() => navigate("products/detail/" + el?.id)}
                  >
                    <div className={`flex-center ${Styles.image}`}>
                      <img
                        src={
                          fileBaseUrl +
                          el?.Files?.filter((url) => url !== "https://")[0]?.url
                        }
                        alt="images-motor"
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div className={Styles.descContent}>
                      <h3>{el?.name} </h3>
                      <div className={Styles.wrappPricing}>
                        <div>
                          <span className={Styles.otr}>
                            Harga OTR Jakarta, mulai dari
                          </span>
                          <span className={Styles.price}>
                            Rp
                            {el?.Variants[0]?.price?.toLocaleString("id-ID") ||
                              0}
                          </span>
                        </div>

                        <div className={Styles.colors}>
                          {el?.VariantColors.slice(0, 4)?.map((color, idx) => (
                            <div className={Styles.chooseColor} key={idx}>
                              <span
                                className={Styles.colorBullet}
                                style={
                                  color?.name?.toLowerCase() === "white" ||
                                  color?.name?.toLowerCase() === "putih" ||
                                  color?.name
                                    ?.toLowerCase()
                                    ?.includes("white") ||
                                  color?.name?.toLowerCase()?.includes("putih")
                                    ? {
                                        border: "0.2px solid #2B2B2B",
                                        background: color?.code,
                                        // background:
                                        //   "linear-gradient(244deg, #D9D9D9 7%, #bcb1b1 51.29%, #D9D9D9 93%)",
                                      }
                                    : { background: color?.code }
                                }
                              ></span>
                            </div>
                          ))}
                          {el?.VariantColors?.length > 4 ? (
                            <span>+{el?.VariantColors?.length - 4}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              className={Styles.buttonMenu}
              onClick={() => {
                navigate("products");
              }}
            >
              <span>Lihat Semua Motor</span>
            </div>
          </section>

          {openResponsive ? (
            <div className={Styles.bodyResponsive}>
              <div className={Styles.boxResponsive}>
                <div className={Styles.topBox}>
                  <div className={Styles.logoBox} onClick={() => navigate("/")}>
                    <img src={LOGO} alt="LOGO" className={Styles.imageLogo} />
                    <img
                      src={LOGO_TEXT_BLACK}
                      alt="LOGO_TEXT_BLACK"
                      className={Styles.textLogo}
                    />
                  </div>
                  <div className={Styles.close}>
                    <Icon
                      icon={"x-mark"}
                      className={Styles.xMark}
                      onClick={() => setOpenResponsive(false)}
                    />
                  </div>
                </div>

                <div className={Styles.buttomBox}>
                  <div className={Styles.routesTop}>
                    <div
                      className={Styles.route}
                      onClick={() => {
                        if (openProductResponsive === false) {
                          setOpenProductResponsive(true);
                        } else {
                          setOpenProductResponsive(false);
                        }
                      }}
                    >
                      <span className={Styles.titleRoute}>Produk</span>
                      {openProductResponsive ? (
                        <Icon icon={"chevron-up"} size={16} />
                      ) : (
                        <Icon icon={"chevron-down"} size={16} />
                      )}
                    </div>

                    {openProductResponsive ? (
                      <div className={Styles.listProduct}>
                        <Link
                          to="/products"
                          className={Styles.list}
                          onClick={() => {
                            closeDropdown();
                          }}
                        >
                          Motor
                        </Link>
                        <Link
                          to={"/products"}
                          className={Styles.list}
                          onClick={closeDropdown}
                        >
                          Spareparts
                        </Link>
                      </div>
                    ) : null}

                    {/* <Link
                      to={"/services"}
                      className={Styles.route}
                      onClick={closeDropdown}
                    >
                      <span className={Styles.titleRoute}>Servis Motor</span>
                    </Link>

                    <Link
                      to={"/promo"}
                      className={Styles.route}
                      onClick={closeDropdown}
                    >
                      <span className={Styles.titleRouteRed}>Promo</span>
                    </Link> */}
                  </div>
                  {/* {isLogin ? null : (
                    <div className={Styles.routesButtom}>
                      <div
                        className={Styles.routeRegister}
                        onClick={() => setShowLogin(true)}
                      >
                        Masuk
                      </div>

                      <div
                        className={Styles.routeRegister}
                        onClick={() => setShowRegister(true)}
                      >
                        Daftar
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              <div
                className={Styles.closeBox}
                onClick={() => setOpenResponsive(false)}
              ></div>
            </div>
          ) : null}

          <div className={Styles.responsiveBox}>
            <Icon
              icon={"bars-3"}
              size={32}
              color={
                "white"
                // pathname === `/products/detail/${id}`
                //   ? "white"
                //   : pathname === `/products`
                //   ? "white"
                //   : "black"
              }
              onClick={() => setOpenResponsive(true)}
            />
          </div>

          {/* <div className={Styles.rigtResponsive}>
            <Icon
              icon={"magnifying-glass"}
              size={24}
              color={pathname === `/products/detail/${id}` ? "white" : "black"}
              onClick={closeDropdown}
            />
            {isLogin ? (
              <>
                <Link to={"/cart"} onClick={closeDropdown}>
                  <Icon
                    icon={"cart"}
                    size={24}
                    color={
                      pathname === `/products/detail/${id}` ? "white" : "black"
                    }
                  />
                  {countcart > 0 ? (
                    <div className={Styles.countCart}>{countcart}</div>
                  ) : (
                    ""
                  )}
                </Link>
                <div
                  className={Styles.routeUser}
                  onClick={() => {
                    if (openUserDropdownResponsive === false) {
                      setOpenUserDropdownResponsive(true);
                    } else {
                      setOpenUserDropdownResponsive(false);
                    }
                  }}
                >
                  <Icon
                    icon={"user"}
                    size={24}
                    color={
                      pathname === `/products/detail/${id}` ? "white" : "black"
                    }
                  />
                  {openUserDropdownResponsive ? (
                    <Icon
                      icon={"chevron-up"}
                      size={20}
                      color={
                        pathname === `/products/detail/${id}`
                          ? "white"
                          : "black"
                      }
                    />
                  ) : (
                    <Icon
                      icon={"chevron-down"}
                      size={20}
                      color={
                        pathname === `/products/detail/${id}`
                          ? "white"
                          : "black"
                      }
                    />
                  )}
                </div>
              </>
            ) : null}
          </div> */}

          <Link to={"/"} className={Styles.leftLogo}>
            <img src={LOGO} alt="PermataMotoLogo" className={Styles.logo} />
            <div
              className={`flex`}
              style={{ alignItems: "center", gap: "24px" }}
            >
              <img
                src={LOGO_TEXT}
                alt="PermataMotoLogo"
                className={Styles.logoText}
              />
              <img
                src={YAMAHA_LOGO}
                style={{ height: "70px" }}
                alt="yamaha-logo"
                className={Styles.logoText}
              />
            </div>
          </Link>

          <div className={Styles.right}>
            <div className={Styles.routeLeft}>
              <div
                className={Styles.route}
                onClick={() => {
                  setIsOpenMenuMotor(!isOpenMenuMotor);
                  // if (openProduct === false) {
                  //   setOpenProduct(true);
                  // } else {
                  //   setOpenProduct(false);
                  // }
                }}
              >
                <span
                  className={`${Styles.titleRoute} ${Styles.titleLight} ${
                    (pathname === `/products/detail/${id}` &&
                      Styles.titleLight) ||
                    (pathname === `/products` && Styles.titleLight)
                  }`}
                >
                  Produk
                </span>
                <Icon
                  icon={"chevron-down"}
                  style={
                    isOpenMenuMotor
                      ? { transform: "rotate(180deg)", transition: ".2s" }
                      : { transition: ".2s" }
                  }
                  size={20}
                  color={
                    "white"
                    // pathname === `/products/detail/${id}`
                    //   ? "white"
                    //   : pathname === `/products`
                    //   ? "white"
                    //   : "black"
                  }
                />
              </div>
              {openProduct ? (
                <div className={Styles.boxDropdown}>
                  <Link
                    to={"/products"}
                    className={Styles.title}
                    onClick={() => {
                      closeDropdown();
                    }}
                  >
                    Motor
                  </Link>
                  <Link
                    to={"/products?t=spareparts"}
                    className={Styles.title}
                    onClick={closeDropdown}
                  >
                    Spareparts
                  </Link>
                </div>
              ) : null}

              {/* <Link
                to={"/servis"}
                className={Styles.route}
                onClick={closeDropdown}
              >
                <span
                  className={`${Styles.titleRoute} ${
                    pathname === `/products/detail/${id}` && Styles.titleLight
                  }`}
                >
                  Servis Motor
                </span>
              </Link> */}

              <div
                // to={"/products}"}
                className={Styles.route}
                onClick={() => navigate("/products?promo=promo")}
              >
                <span
                  className={`${Styles.titleRouteRed} ${Styles.titleLight} ${
                    (pathname === `/products/detail/${id}` &&
                      Styles.titleLight) ||
                    (pathname === `/products` && Styles.titleLight)
                  }`}
                >
                  Promo
                </span>
              </div>
            </div>

            {/* <div className={Styles.routeRight}>
              <div className={Styles.route}>
                <Icon
                  icon={"magnifying-glass"}
                  size={24}
                  color={
                    pathname === `/products/detail/${id}` ? "white" : "black"
                  }
                  onClick={closeDropdown}
                />
              </div>

              {isLogin ? (
                <>
                  <Link
                    to={"/cart"}
                    className={Styles.route}
                    onClick={closeDropdown}
                  >
                    <Icon
                      icon={"cart"}
                      size={24}
                      color={
                        pathname === `/products/detail/${id}`
                          ? "white"
                          : "black"
                      }
                    />{" "}
                    {countcart > 0 ? (
                      <div className={Styles.countCart}>{countcart}</div>
                    ) : (
                      ""
                    )}
                  </Link>
                  <div
                    className={Styles.routeUser}
                    onClick={() => {
                      if (openUserDropdown === false) {
                        setOpenUserDropdown(true);
                      } else {
                        setOpenUserDropdown(false);
                      }
                    }}
                  >
                    <Icon
                      icon={"user"}
                      size={24}
                      color={
                        pathname === `/products/detail/${id}`
                          ? "white"
                          : "black"
                      }
                    />

                    <Icon
                      icon={"chevron-down"}
                      style={
                        openUserDropdown
                          ? { transform: "rotate(180deg)", transition: ".2s" }
                          : { transition: ".2s" }
                      }
                      size={20}
                      color={
                        pathname === `/products/detail/${id}`
                          ? "white"
                          : "black"
                      }
                    />
                  </div>
                  {openUserDropdown ? (
                    <div className={Styles.boxUserDropdown}>
                      <div className={Styles.userBox}>
                        <Icon icon={"user"} size={24} />
                        <div className={Styles.nameBox}>
                          <span className={Styles.name}>Satoru Iguchi</span>
                          <span className={Styles.email}>
                            Iguchitora@gmail.com
                          </span>
                        </div>
                      </div>

                      <div className={Styles.menuUserBox}>
                        <Link
                          to={"/histori"}
                          className={Styles.text}
                          onClick={closeDropdown}
                        >
                          Histori Transaksi
                        </Link>
                        <span className={Styles.text} onClick={closeDropdown}>
                          Pengaturan
                        </span>
                      </div>

                      <div
                        className={Styles.logOutUser}
                        onClick={closeDropdown}
                      >
                        <span className={Styles.text}>Logout</span>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div
                    className={Styles.route}
                    onClick={() => setShowLogin(true)}
                  >
                    <span
                      className={`${Styles.titleRoute} ${
                        pathname === `/products/detail/${id}` &&
                        Styles.titleLight
                      }`}
                    >
                      Masuk
                    </span>
                  </div>
                  <div
                    className={Styles.routeRegister}
                    onClick={() => setShowRegister(true)}
                  >
                    Daftar
                  </div>
                </>
              )}
            </div> */}
          </div>
        </div>

        {openUserDropdownResponsive ? (
          <div className={Styles.bodyUserDropdown}>
            <div
              className={`${Styles.boxUserDropdownResponsive} ${
                pathname === `/products/detail/${id}` && Styles.darkBackground
              }`}
            >
              <div
                className={`${Styles.userBox} ${
                  pathname === `/products/detail/${id}` && Styles.userBoxLight
                }`}
              >
                <Icon
                  icon={"user"}
                  size={24}
                  color={
                    pathname === `/products/detail/${id}` ? "white" : "black"
                  }
                />
                <div className={Styles.nameBox}>
                  <span
                    className={`${Styles.name} ${
                      pathname === `/products/detail/${id}` && Styles.nameLight
                    }`}
                  >
                    Satoru Iguchi
                  </span>
                  <span
                    className={`${Styles.email} ${
                      pathname === `/products/detail/${id}` && Styles.nameLight
                    }`}
                  >
                    Iguchitora@gmail.com
                  </span>
                </div>
              </div>

              <div
                className={`${Styles.menuUserBox} ${
                  pathname === `/products/detail/${id}` &&
                  Styles.menuUserBoxLight
                }`}
              >
                <Link
                  to={"/histori"}
                  className={`${Styles.text} ${
                    pathname === `/products/detail/${id}` && Styles.nameLight
                  }`}
                  onClick={closeDropdown}
                >
                  Histori Transaksi
                </Link>
                <span
                  className={`${Styles.text} ${
                    pathname === `/products/detail/${id}` && Styles.nameLight
                  }`}
                  onClick={closeDropdown}
                >
                  Pengaturan
                </span>
              </div>

              <div className={Styles.logOutUser}>
                <span
                  className={`${Styles.text} ${
                    pathname === `/products/detail/${id}` && Styles.nameLight
                  }`}
                  onClick={closeDropdown}
                >
                  Logout
                </span>
              </div>
            </div>
            <div
              className={Styles.null}
              onClick={() => setOpenUserDropdownResponsive(false)}
            ></div>
          </div>
        ) : null}

        {showLogin && (
          <Modal handleDone={() => setShowLogin(false)}>
            <LoginShow
              handleDone={() => setShowLogin(false)}
              handleRoute={() => {
                setShowLogin(false);
                setShowRegister(true);
              }}
            />
          </Modal>
        )}

        {showRegister && (
          <Modal handleDone={() => setShowRegister(false)}>
            <RegisterShow
              handleDone={() => setShowRegister(false)}
              handleRoute={() => {
                setShowLogin(true);
                setShowRegister(false);
              }}
            />
          </Modal>
        )}
      </div>
      <section>{children}</section>
      <Footer />
    </>
  );
};

export default NavbarLandingPage;
