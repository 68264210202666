import React from "react";

export default function SearchIcon({ color = "#FFFFFF", props }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8874 11.4152H12.1151L11.8414 11.1514C13.0145 9.78349 13.6206 7.91733 13.2882 5.93391C12.8288 3.21771 10.5607 1.04865 7.82333 0.716456C3.68798 0.208389 0.207632 3.68669 0.715997 7.81962C1.04839 10.5554 3.21872 12.8221 5.93651 13.2813C7.92109 13.6135 9.78835 13.0078 11.157 11.8353L11.421 12.1089V12.8807L15.5759 17.0332C15.9767 17.4338 16.6317 17.4338 17.0326 17.0332C17.4334 16.6326 17.4334 15.978 17.0326 15.5774L12.8874 11.4152ZM7.02168 11.4152C4.58739 11.4152 2.62236 9.4513 2.62236 7.01844C2.62236 4.58558 4.58739 2.62171 7.02168 2.62171C9.45596 2.62171 11.421 4.58558 11.421 7.01844C11.421 9.4513 9.45596 11.4152 7.02168 11.4152Z"
        fill={color}
      />
    </svg>
  );
}
