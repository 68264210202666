import React, { useState } from "react";
import Styles from "./styles.module.scss";

export default function ImageMagnifier({
  width = "100%",
  height = "100%",
  magnifierHeight = 200,
  magnifierWidth = 200,
  zoomLevel = 2,
  src = "",
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const handleMouseEnter = (e) => {
    const element = e.currentTarget;
    const { width, height } = element.getBoundingClientRect();
    setSize([width, height]);
    setShowMagnifier(true);
  };

  const handleMouseHover = (e) => {
    const element = e.currentTarget;
    const { top, left } = element.getBoundingClientRect();

    const x = e.pageX - left - window.scrollX;
    const y = e.pageY - top - window.scrollY;
    setXY([x, y]);
  };

  const handleMouseLeave = (e) => {
    setShowMagnifier(false);
  };

  return (
    <div
      className={Styles.magnifier}
      style={{
        height,
        width,
      }}
    >
      <img
        src={src}
        className={Styles.magnifierImage}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseHover}
        onMouseLeave={handleMouseLeave}
        style={{
          filter: showMagnifier ? "blur(3px)" : "none",
        }}
        alt={"motor-img"}
      />

      <div
        className={Styles.magnifiedImage}
        style={{
          display: showMagnifier ? "" : "none",
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          opacity: "1",
          backgroundImage: `url('${src}')`,

          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
}
// import React, { useState, useEffect } from "react";
// import Styles from "./styles.module.scss";

// export default function ImageMagnifier({
//   width = "100%",
//   height = "100%",
//   magnifierHeight = 200,
//   magnifierWidth = 200,
//   zoomLevel = 2,
//   src = "",
// }) {
//   const [[x, y], setXY] = useState([0, 0]);
//   const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
//   const [showMagnifier, setShowMagnifier] = useState(false);

//   const handleMouseEnter = () => {
//     setShowMagnifier(true);
//   };

//   const handleMouseHover = (e) => {
//     const element = e.currentTarget;
//     const { top, left } = element.getBoundingClientRect();

//     const x = e.pageX - left - window.scrollX;
//     const y = e.pageY - top - window.scrollY;
//     setXY([x, y]);
//   };

//   const handleMouseLeave = () => {
//     setShowMagnifier(false);
//   };

//   return (
//     <div
//       className={Styles.magnifier}
//       style={{
//         height,
//         width,
//       }}
//     >
//       <img
//         src={src}
//         className={Styles.magnifierImage}
//         onMouseEnter={handleMouseEnter}
//         onMouseMove={handleMouseHover}
//         onMouseLeave={handleMouseLeave}
//         style={{
//           filter: showMagnifier ? "blur(3px)" : "none",
//         }}
//         alt={"motor-img"}
//       />

//       {showMagnifier && (
//         <div
//           className={Styles.magnifiedImage}
//           style={{
//             height: `${magnifierHeight}px`,
//             width: `${magnifierWidth}px`,
//             top: `${y - magnifierHeight / 2}px`,
//             left: `${x - magnifierWidth / 2}px`,
//             backgroundImage: `url('${src}')`,
//             backgroundSize: `${imgWidth * zoomLevel}px ${
//               imgHeight * zoomLevel
//             }px`,
//             backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
//             backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
//           }}
//         ></div>
//       )}
//     </div>
//   );
// }
